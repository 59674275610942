// collection Cursos
//idcurso:cpTO2Hbr2kB6n6q1g1eq

//AL INGRESAR CORREO VERIFICAR SI EXISTE EN _USERS  
// Y VERIFICAR CORREO EN EL EVENTO

//datos
// nombre ,correo, 

import React ,{ Fragment, useState, useEffect, useRef }from 'react';
import 'react-phone-number-input/style.css';
import Barcode from 'react-barcode';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver'

import PhoneInput, {/* formatPhoneNumber, formatPhoneNumberIntl,*/ isValidPhoneNumber } from 'react-phone-number-input'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import {Redirect, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';

//import {auth} from '../../config/firebase';

import { useDispatch, useSelector } from 'react-redux';
import { registerEmailPassSignUp, startClearError, startClearSuccess } from '../../redux/actions/auth';
//import { getUser } from '../../redux/actions/user';
//import { startGoogleAuth, startLogOut } from '../../redux/actions/auth';
import Modal from '../components/Modal';
import Panel from './components/Panel';
import {getInscritoByEmail, userExist, generateBarCode, registerData} from './services/events';

export default function Signup() {

  const { register, handleSubmit, formState: { errors }, getValues } = useForm();

  const dispatch = useDispatch();
	const { idEvent } = useParams();
	const imgContainer = useRef(null);

  const reduxAuth = useSelector(state => state.auth);
  const cart = useSelector(state => state.cart);

	const [eventId, setEventId] = useState("");
  const [phone , setPhone] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [password, setPassword] = useState();
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPassword2, setErrorPassword2] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [ciudad , setCiudad] = useState();

  const [panel, setPanel] = useState(1);
	const [errorPanel1, setErrorPanel1] = useState(false);
	const [panel1Message, setPanel1Message] = useState('');
	const [errorPanel2, setErrorPanel2] = useState(false);
	const [panel2Message, setPanel2Message] = useState('');


	const [isNewUser, setIsNewUser ] = useState(true);
	const [userId, setUserId] = useState('');
	const [modalidad, setModalidad] = useState('');	
	const [barCode, setBarCode] = useState('');

  const [ whatsappModal, setWhatsappModal] = useState(false);
  const [telefono, setTelefono] = useState();

  const [buttonDisabled, setButtonDisabled] = useState(false);
	const [succesRedirect, setSuccesRedirect] = useState(false);
  //const [extencion,setExtencion] = useState();
  //const [errorExtencion,setErrorExtencion] = useState(false);
	const exportToPng = (dom) =>{
		domtoimage
			.toPng(dom)
			.then(function (dataUrl) {
				saveAs(dataUrl, 'apriori-bar-code.png')
				
			})
			.catch(function (error) {
				console.error("oops, something went wrong!", error);
			});
	}

  const closeErrorModal = () => {
    setErrorModal(false);
    dispatch(startClearError());
  }

  const closeSuccessModal = () => {
    setSuccessModal(false);
    dispatch(startClearSuccess());
    setIsAuthenticated(true);
  }

  const submit = async () =>{
		try{
			console.log(userData)
			setButtonDisabled(true)
			const result = await registerData(userData)
			if(result === 'ok'){
				setSuccessModal(true);
				setButtonDisabled(false);
			}
		} catch(error){
			console.log(error)
			setErrorModal(true);
			setButtonDisabled(false)
		}
    // dispatch(registerEmailPassSignUp(userData));
  }

//datos del usuario celular ciudad y pais 
  const onSubmit = (data, e) => {
		console.log("aquiiiiiiiiiiiiiiiiiiiii s");
    if(phone){
      if(isValidPhoneNumber(phone)){
        data.phone = phone;
        setErrorPhone(false);
      } else{
				setErrorPhone(true);
				setTimeout(() => {
					setErrorPhone(false);
				}, 1000);
        return
      }
    }else {
      setErrorPhone(true);
			setTimeout(() => {
				setErrorPhone(false);
			}, 1000);
      return
    }
    let day=startDate.getDate();
    let month=startDate.getMonth()+1;
    const year=startDate.getFullYear();
    if(day<10){
      day=`0${day}`;
    }
    if(month<10){
      month=`0${month}`;
    }

    data.birthday = `${day}/${month}/${year}`;
		if (isNewUser){
			if(data.password.length<8){
				setErrorPassword('la contraseña debe tener al menos 8 caracteres');
				return
			}
			if(data.password !== data.password2){
				setErrorPassword2('Las contraseñas no coinciden');
				return
			}
		}
		if(!data.preferencia){
			data.preferencia='Ninguno';
		}
		data.codigoBarras=barCode;
		data.eventId=eventId;
		data.isNewUser=isNewUser;
		data.modalidad=modalidad;
		data.userId=userId;
    setErrorPassword('');
    setErrorPassword2('');
    console.log(data);    
    setUserData(data);
    setModal(true);
  };

  const setPhones = (e)=> {
    setPhone(e);
  }
  const validPass = (e,p)=>{
    //console.log(e.target.value,p);
    if(p==='p1'){
      if(e.target.value.length>=8){
        console.log('entro: ',e.target.value.length);
        setErrorPassword('');
        setPassword(e.target.value);
      }
      else{
        setErrorPassword('La contraseña debe tener al menos 8 caracteres');
      }
    }
    else{
      if(e.target.value===password){
        setErrorPassword2('');
      }
      else{
        setErrorPassword2('Las contraseñas no coinciden');
      }
    }

  }
	const nextButtonPanel1 = async () => {
		const data = getValues();
		console.log(data,"dataaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
		
		if(!data.email || !data.name || !data.lastName || data.grado === '0' || data.city==='0'){
			setErrorPanel1(true);
			setPanel1Message('Complete el formulario');
			return;
		}
		const emailVal=/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
		if (!emailVal.test(data.email)){
			setErrorPanel1(true);
			setPanel1Message('Ingrese un correo valido');
			return
		 }
		setErrorPanel1(false);
		const exist = await getInscritoByEmail(eventId,data.email)
		if(data.city==='chuquisaca'){
			setModalidad('presencial')
		} else {
			setModalidad('virtual')
		}
		if(exist === 'existe'){
			setErrorPanel1(true);
			setPanel1Message('El correo ya se encuentra inscrito al evento');
			return;
		}
		setPanel(panel+1);

		const userDataId = await userExist(data.email);
		if(userDataId === 'no Existe'){
			setIsNewUser(true);
		} else {
			setIsNewUser(false);
			setUserId(userDataId);
		}
		
	}

	const getBarCode = async (ei) =>{
		const code= await generateBarCode(ei);
		setBarCode(code)
	}
	/*
  useEffect(()=>{
    if(reduxAuth.errorRegister){
      console.log('error al crear la cuenta: ')
      console.log(reduxAuth.errorRegister);
      setModal(false);
      setErrorModal(true);
    }
    setButtonDisabled(reduxAuth.loadingRegister);

    if(reduxAuth.success){
      setModal(false);
      setSuccessModal(true);
    }

    if(reduxAuth){
      console.log('estado de redux');
      console.log(reduxAuth);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reduxAuth]);
*/
const sendWhatsapp = () => {
  if (telefono.length > 7 && telefono.length < 9) {
    const bnb ='*CUENTA DE DEPOSITO:*Banco :* Banco Nacional de Bolivia (BNB) *Nombre del Titular :* Leonel Avendaño Villarroel *Numero de cuenta :* 4500837492 *Carnet de Identidad :* 10377499 ______';
    const bisa = "*CUENTA DE DEPOSITO:*Banco :* Banco Bisa *Nombre del Titular :* Abigail Mamani *Numero de cuenta :*  495340-401-5 *Carnet de Identidad :* 13427301 pt ___";
    const union = "*CUENTA DE DEPOSITO: *Banco :* Banco Unión *Nombre del Titular :* Leonel Avendaño Villarroel *Numero de cuenta :*  10000042944682 - Caja de ahorro M/N *Carnet de Identidad : *10377499  ___";
    const prodem = "*CUENTA DE DEPOSITO:*Banco :* Banco Prodem *Nombre del Titular :* Rocely Valeria Cruz *Numero de cuenta :*  703-2-1-03486-9 *Carnet de Identidad :* 10330317 ";
    let mensaje= bnb + bisa + union + prodem;
    const url = `https://api.whatsapp.com/send?phone=591${telefono}&text=${mensaje}`;
    window.open(url, '_blank');
  }else{
    alert("Introducir un numero de telefono valido");
  }
}
	const colseModalSuccess = () =>{
			setSuccessModal(false);
			setTimeout(() => {
				window.location.replace("https://aprioribo.com");
			} , 1000);
		
	}
  useEffect(()=>{
		setEventId(idEvent);
	},[idEvent]);

	useEffect(()=>{
		if(eventId!==''){
			getBarCode(eventId);
		}
	},[eventId]);

  return (
    <Fragment>
			{succesRedirect?<Redirect to={`/`} />:null}
      <div className="w-full flex flex-col justify-center items-center h-5/6 mt-16 bg-white sm:bg-white md:bg-white ">
      <form onSubmit={handleSubmit(onSubmit)} className="w-1/3 border-white rounded mt-8 sm:w-5/6 md:w-1/3">
        <div className='bg-white rounded shadow-2xl bg-gray-100 sm:bg-white md:bg-gray-100 sm:shadow-none md:shadow-2xl'>
          <h1 className=" text-red-500 text-center pt-5 text-2xl">Registrarse al evento</h1> 
          <Panel 
            btnNext={()=>nextButtonPanel1()}
            panelId={1}
            panelSelect={panel}
						error={errorPanel1}
						errorMessage={panel1Message}
          >
						<input
                name="name"
                placeholder="Nombre"
                className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 sm:focus:border-3 md:focus:border-t-3 focus:border-red-500 w-full"
                {...register("name",{required:true})}
            />
						<p className="text-xs text-red-500">{errors.name?.type === 'required' && "*Debe introducir nombre"}</p>
            <input
                name="lastName"
                placeholder="Apellido"
                className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
                {...register("lastName",{required:true})}
            />
            <p className="text-xs text-red-500">{errors.lastName?.type === 'required' && "*Debe introducir su nombre completo"}</p>
						<input
								name="email"
								placeholder="Correo Electronico"
								className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500"
								type="email"
								{...register("email",{required:true})}
						/>
						<p className="text-xs text-red-500"> {errors.email?.type === 'required' && "*Debe introducir correo Electronico"}</p>
						<select 
              {...register("grado",{required:true})} 
              name='grado'
              className="p-3 my-1 bg-gray-200 w-full mt-3 rounded-lg"
              >
                <option value="0">Grado</option>
                <option value="Lic">Lic.</option>
                <option value="Msc">Msc.</option>
                <option value="PhD">PhD.</option>
                <option value="Ninguno">Ninguno</option>
            </select>
						<select 
              {...register("city",{required:true})} 
              name='city'
              className="p-3 my-1 bg-gray-200 w-full mt-3 rounded-lg"
              >
                <option value="0">Lugar de residencia</option>
                <option value="chuquisaca">Chuquisaca</option>
                <option value="laPaz">La Paz</option>
                <option value="cochabamba">Cochabamba</option>
                <option value="oruro">Oruro</option>
                <option value="potosi">Potosi</option>
                <option value="tarija">Tarija</option>
                <option value="pando">Pando</option>
                <option value="beni">Beni</option>
                <option value="santaCruz">Santa Cruz</option>
            </select>
						
          </Panel>
          <Panel
            btnPrev={()=>setPanel(panel-1)}
            btnSubmit={()=>handleSubmit(onSubmit)}
            panelId={2}
            panelSelect={panel}
						error={errorPanel2}
						errorMessage={panel2Message}
          >
						<p className="text-x text-green-500"> Modalidad: {modalidad}</p>
						{
							modalidad === 'presencial'?
										<select 
										{...register("preferencia",{required:true})} 
										name='preferencia'
										className="p-3 my-1 bg-gray-200 w-full mt-3 rounded-lg"
										>
											<option value="Ninguno">Refrigerio(indique si tiene alguna restricción alimenticia)</option>
											<option value="Sin gluten">Sin gluten</option>
											<option value="vegano">vegano</option>
											<option value="Ninguno">Ninguno</option>
									</select>
									:
									<>
										<input
											name="direccion"
											placeholder="Direccion para envio"
											className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500"
											{...register("direccion",{required:true})}
										/>
										<p className="text-x text-green-500">Direccion para envio de certificados (ser especifico para el envio por courrier)</p>
									</>
						}
						{
							isNewUser ?
							<>
								<input
										name="password"
										type="password"
										placeholder="Contraseña"
										onChangeCapture={(e)=>validPass(e,'p1')}
										className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500"
										{...register("password", {required:true})}
								/>
								{errorPassword && <p className="bg-red-600">{errorPassword}</p>}
								<input
										name="password2"
										type="password"
										placeholder="Repetir Contraseña"
										className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
										onChangeCapture={(e)=>validPass(e,'p2')}
										{...register("password2", {required:true})}
								/>
								{errorPassword2 && <p className="bg-red-600">{errorPassword2}</p>}
							</>
							:
							null
						}

						{
							isNewUser ?
							<p className="text-xs text-green-500"> Crea un nueva contraseña para acceder a la transmisión</p>:null
							
						}
						<PhoneInput
							className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
							placeholder="Numero de celular"
							onChange={setPhones}
							defaultCountry="BO"
							value={phone}
							error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
						/>
						<p className="text-xs text-red-500">{
							phone === undefined ||phone === ''?'':phone && isValidPhoneNumber(phone) ? '' : '*Numero invalido'}
						</p>
						<p className="text-xs text-red-500">
							{ 
								errorPhone?'ingrese un numero de celular valido':''
							}
						</p>

						
          </Panel>
        </div>
      </form>
      <Modal
      visible={modal}
      title="Verifica tus datos"
      onClose={()=>setModal(false)}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-1000">
            Nombre: {userData.name}
          </p>
          <p className="text-sm text-gray-1000">

            Apellido: {userData.lastName}
          </p>
          <p className="text-sm text-gray-1000">
            Lugar de residencia: {userData.city}
          </p>
					{/*
          <p className="text-sm text-gray-1000">
            Fecha de nacimiento: {userData.birthday}
						</p>*/}
          <p className="text-sm text-gray-1000">
            Numero de celular: {userData.phone}
          </p>
          <p className="text-sm text-gray-1000">
            Correo Electronico: {userData.email}
          </p>
        </div>
        <div className="mt-4">
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-100 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
          onClick={()=>setModal(false)}
          
        >
          Cancelar
        </button>
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-green-1000 bg-green-400 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={()=>submit()}
          disabled={buttonDisabled}
        >
          {!buttonDisabled && <span className="ml-2">Continuar</span>}
          {buttonDisabled && <span className="ml-2">Cargando...</span>}
        </button>

        </div>
      </Modal>
      <Modal
      visible={errorModal}
      title="Ocurrio un error"
      onClose={()=>setErrorModal(false)}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-1000">
            Error al registrar, intentelo nuevamente
          </p>
        </div>
        <div className="mt-4">
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-100 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
          onClick={()=>setErrorModal(false)}	
        >
          Aceptar
        </button>
        </div>
      </Modal>
      <Modal
      visible={whatsappModal}
      title="eviar datos de la cuenta al whatsapp"
      onClose={()=>setWhatsappModal(false)}
      >
        <p className="text-sm">Numero de celular:</p>
        <div className="flex flex-row justify-center items-center">
          <p className="text-base">+591 </p>
          <input value={telefono} onChange={(e)=>setTelefono(e.target.value)} className="border-b-2 border-b-gray-400 w-full p-2" type="text" placeholder="75413879"/>
          <div className="flex flex-row justify-end items-center self-end">
            <button onClick={()=>{setWhatsappModal(false)}} className="hover:bg-red-700 hover:text-white text-primary mt-4 font-bold py-1 px-4 rounded border-2 border-primary mr-2">Cerrar</button>
            <button onClick={()=>sendWhatsapp() } className={`bg-green-500 cursor-pointer mt-4 text-white p-2 rounded text-sm self-end`}>Enviar a Whatsapp</button>
          </div>
        </div>
      </Modal>

      <Modal
      visible={successModal}
      title="Registro exitoso"
      onClose={()=>console.log("close")}
      >
        <div className="mt-2 max-h-96 overflow-auto">
          <p className="text-sm text-primary text-center mt-4">
            <b>Datos registrados correctamente</b>
						</p>
						<p className="text-sm text-gray-1000 mt-2">
						Para validad su inscripción debe realizar el pago de:
						</p>
            <ul className='ml-6'>
              <li type="square" className="text-sm text-black">
                Público en general: Bs. 150.
              </li>
              <li type='square' className="text-sm text-black">
                Clientes antiguos: Bs. 135.
              </li>
              <li type='square' className="text-sm text-black">
                Colaboradores: Bs. 130.
              </li>

            </ul>
						<p className="text-sm text-gray-1000 mt-4"><b>El pago de su inscripción cubre:</b></p>
						<ul className='ml-6'>
              <li type='square' className="text-sm text-black">Acceso al evento.</li>
              <li type='square' className="text-sm text-black">Refrigerio (modalidad presencial).</li>
              <li type='square' className="text-sm text-black">Envió de certificados por courier (modalidad virtual).</li>
              <li type='square' className="text-sm text-black">Material.</li>
            </ul>
            <p className="text-sm text-justify">
            <br/>
            El Certificado del evento estará firmado por el expositor, representante legal Apriori, Fundación Actívate, Federación de Empresarios Privados de Chuquisaca, Universidad San Francisco Xavier de Chuquisaca, Gobierno Autónomo Municipal de Sucre.
            </p>
            <p className="text-sm text-justify">
            <br/>
            <b className="text-sm text-primary">Nota.-</b>
            Al finalizar el evento, el participante tendrá un lapso de 24 horas para realizar una prueba de conocimientos sobre el tema desarrollado.
            </p>
            <button 
							className="min-w-1/2 ml-3 py-2 px-4 my-4 border-2 border-green-500 font-semibold rounded-lg shadow-md text-white bg-green-500 hover:bg-transparent hover:text-primary mt-3" 
							onClick={()=>setWhatsappModal(true)}
						>
							Enviar datos de cuenta a mi whatsapp
						</button>
						<p className="text-sm text-red-500 ">
              <b className='text-center'>Descargue el código de barras o tome una foto a la imagen para marcar su asistencia (deberá enseñar este código para ingresar al evento presencial)</b>
            </p>

						<div id='barcode' ref={imgContainer}>
							<Barcode value={barCode} />					
						</div>
						<button 
							className="min-w-1/2 ml-3 py-2 px-4 border-2 border-green-500 font-semibold rounded-lg shadow-md text-white bg-green-500 hover:bg-transparent hover:text-primary mt-3" 
							onClick={()=>exportToPng(imgContainer.current)}
						>
							Descargar codigo de barras
						</button>
        </div>
        <div className="mt-4">
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium font-bold text-white bg-primary border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={()=>colseModalSuccess()}
        >
          Aceptar
        </button>
        </div>
      </Modal>        
      
      </div>
  </Fragment>
    );
}
