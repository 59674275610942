import React ,{useEffect,useState} from 'react';
import {Link ,Redirect } from "react-router-dom";
import { useDispatch, useSelector  } from 'react-redux';
import { startGoogleAuth, startLogOut } from '../../redux/actions/auth';
import Form from './Form';
import {auth} from '../../config/firebase';

import { FaApple, FaFacebookF, FaGoogle } from "react-icons/fa";

export default function Login(){

    const reduxAuth = useSelector(state => state.auth);

    const [isUser, setIsUser] = useState(false);
    const dispatch = useDispatch();
    
    const handleGoogleAuth = () =>{
        dispatch(startGoogleAuth());
    }

    const logOut = () =>{
        dispatch(startLogOut());
    }

    useEffect(()=>{
        if(reduxAuth.errorRegister){
          console.log('error al crear la cuenta: ')
          console.log(reduxAuth.errorRegister);
        }
        
    
        if(reduxAuth.isAuthenticated){
          console.log('usuario creado');
          if(auth.currentUser){
            const userID = auth.currentUser.uid;
           
          } else {
            console.log('no hay usuario loggueado');
          } 
          
        }

        if(reduxAuth){
          console.log('estado de redux');
          console.log(reduxAuth);

          setIsUser(true);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[reduxAuth]);

    return(
    <>
        <div className=" flex justify-center h-screen -mt-14 z-0 " >
            <div className="flex flex-row justify-center  items-center sm:flex-col md:flex-row ">
                <div>
                    <Form/>{/*
                    <p>o desea {' '}
                    <span onClick={logOut} className="text-primary text-center cursor-pointer hover:underline">
                    cerrar sesión.
                        </span></p>*/}
                </div>
                <p className="mx-5" >o</p>
                <div className = " flex flex-col mt-3 justify-end ">
                    <p className="text-sm" >No tienes una cuenta? Registrarse con:</p>
                    {/*
                    <button onClick={()=>console.log("Facebook")} className="flex flex-row justify-between items-center  my-3 text-sm shadow-md text-white bg-blue-500 hover:bg-blue-700">
                    <FaFacebookF  size={45} className="bg-blue-600 p-2"/><p className="pr-3">inicie sesión con Facebook</p>
                    </button>
                    <button onClick={handleGoogleAuth} className="flex flex-row justify-between items-center text-sm mb-1  shadow-md text-white bg-red-500 hover:bg-red-700">
                    <FaGoogle size={45} className="bg-red-600 p-2"/><p className="pr-3">inicie sesión con Gmail</p>
                    </button>
                    */}
                    
                    <button className="py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-transparent hover:text-primary mt-3"><Link  to="/signup">crear cuenta</Link></button>
                    {/* <button onClick={()=>console.log('Apple')} className="flex  justify-center mb-1 py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-green-500 hover:bg-green-700">
                    <FaApple/> Apple
                    </button> */}
                </div>            
            </div>
        </div>
    </>
    );
};