import React,{useState} from 'react';
import backgroun from './../../assets/images/abogados.jpg'
import { FaMobileAlt} from 'react-icons/fa';
import { Contactanos } from './../components/uiComponents';

export default function ContentParallax(props){

    var sectionStyle = {
        width: "100%",
        // height: "80vh",
        background: "url("+backgroun+") fixed 50% 50%",
      };

    return(
    <>
     <div  style={sectionStyle} className="p-12 sm:p-4 md:p-12 flex flex-row items-center">
         <div className="bg-white w-2/6 rounded p-10 sm:p-4 md:p-10 sm:w-full md:w-2/3">
          <h3 className="text-primary text-3xl font-bold leading-relaxed">Beneficios Apriori</h3>
          <ul className="ml-4 text-xl sm:text-base"> 
            <li type="disc" className=' mt-2'>Exámenes diseñados estrictamente con base en los ejes temáticos oficiales.</li>
            <li type="disc" className=' mt-2'>Preguntas elaboradas por especialistas que ocupan u ocuparon el cargo al que postulas.</li>
            <li type="disc" className=' mt-2'>Tendrás revisión de cada examen que des, PREGUNTA POR PREGUNTA. </li>
            <li type="disc" className=' mt-2'>Gráficos que te indicaran tu progreso y te ayudaran a identificar en qué materias necesitas reforzar.</li>
            <li type="disc" className=' mt-2'>Soporte personalizado. </li>
            <li type="disc" className=' mt-2'>Disponibilidad 24/7.</li>
          </ul>
         </div>
         
     </div>
    </>
    );
};