import React,{useEffect, useState, useRef} from "react";

import { db } from "../../config/firebase";
import dbRef from "../../config/dbRef";
import { collection, getDocs } from "firebase/firestore";
import { doc, addDoc, updateDoc , query,where } from "firebase/firestore";
import { set } from "react-hook-form";

import { getMaterias, getQuestion,createExam } from "./utils";


const CardCounts = (props) => {
  const { exam, idUser, codeAfiliate } = props;
  const [edit , setEdit] = useState(true);
  const [editAfiliate , setEditAfiliate] = useState(true);
  // referencias a todas las variables
  const cantidadP = useRef();
  const intentos = useRef();
  const estado = useRef();
  const idExamen = useRef();
  const plan = useRef();

  const monstoAfiliate = useRef();


  //metodo asincrono para guardar los datos
  const handleSave = async () => {
    try{
      let cantidad = cantidadP.current.value? cantidadP.current.value : exam.data.cantidadPreguntas;
      let intento = intentos.current.value? intentos.current.value : exam.data.intentos;

      let estadoExamen = estado.current.value ? estado.current.value : exam.data.estado;
      let idExam = idExamen.current.value? idExamen.current.value : exam.data.idExamen;
      let planExamen = plan.current.value? plan.current.value : exam.data.plan;

      const cuenta = doc(db, dbRef.user, idUser,'cuenta', exam.id);
      await updateDoc(cuenta, {
        cantidadPreguntas: cantidad,
        intentos: intento,
        estado: estadoExamen,
        idExamen: idExam,
        plan: planExamen,

      });
      if(exam.data.tipo === "PFinal"){
        console.log("entro a la prueba final");
      }
      alert(`Datos guardados ${cantidad} ${intento} ${estadoExamen} ${idExam} ${planExamen}`);
    }catch(error){
      alert("Error al guardar los datos :"+error);
      console.log(error);
    }

  }

  const handleSaveAfiliate = async () => {
    setEditAfiliate(false);
    try{
      let planExamen = plan.current.value? plan.current.value : exam.data.plan;
      let monto = monstoAfiliate.current.value;

      if(planExamen === "pendiente"){
        alert("El plan no puede ser pendiente");
        setEditAfiliate(true);
        return;
      }
      if(monto === ""){
        alert("El monto no puede estar vacio");
        setEditAfiliate(true);
        return;
      }

      const querySnapshot = await getDocs(
        query(collection(db, dbRef.user),where("code", "==", codeAfiliate))
      );
      if(querySnapshot.size<=0){
        alert("No se encontro el codigo de afiliado");
        setEditAfiliate(true);
        return;
      }

      const afiliate = collection(db, dbRef.user, querySnapshot.docs[0].id, 'afiliates');
      const queryAfiliate = await getDocs(
        query(afiliate,where("afiliate", "==",idUser ))
      );

      if(queryAfiliate.size>0){
        const afiliateDoc = doc(db, dbRef.user, querySnapshot.docs[0].id, 'afiliates', queryAfiliate.docs[0].id);
        await updateDoc(afiliateDoc, {
          plan: planExamen,
          monto: monto,
          state : "pago"
        });
        
      }else{
        alert("No se encontro el codigo de afiliado");
        return;
      }
      alert(`Datos guardados: plan = ${planExamen}. monto: bs ${monto}`);
      setEditAfiliate(true);
        
    }catch(error){
      alert("Error al guardar los datos :"+error);
      console.log(error);
      setEditAfiliate(true);
    }
  }

  return (
    <div className=" max-w-1/3 w-1/3 self-start p-2">
      <div className="bg-gray-300 rounded p-4">
        <h2 className="text-center text-2xl text-primary ">{
          exam.data.tipo === "PPrac" ? "Prueba Practica" : "Prueba Final"
        } </h2>
        <p className="font-bold text-center">{exam.data.nombre}</p>
        <p className="text-center text-xs">{exam.id}</p>
        <p className="text-center text-sm">Fecha de Examen : {exam.data.fechaExamen}</p>
        {
          codeAfiliate && <p className="text-center text-sm">Codigo de Afiliado : {codeAfiliate}</p> 
        }
        <p className="text-center border-b-2 border-gray-500">{exam.data.fechaRegistro}</p>
        <div className="flex flex-row mt-4 justify-between ">
          <p>Cantidad de preguntas : </p>
          <input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-10 mx-2" placeholder={exam.data.cantidadPreguntas} disabled={edit} ref={cantidadP}/>
        </div>
        <div className="flex flex-row my-1 justify-between">
          <p className="text-center">Intentos :</p>
          <input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-10 mx-2" placeholder={exam.data.intentos} disabled={edit} ref={intentos}/>
        </div>
        <div className="flex flex-row my-1 justify-between">
          <p>Estado : </p>
          {
            edit ? <p className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2">{exam.data.estado}</p> 
            : 
            <select className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2" disabled={edit} ref={estado}>
              
              <option value="pendiente"
                className={`${exam.data.estado === 'pendiente' ? "bg-blue-400": null }`}
                selected={exam.data.estado.includes("ente")}
              >pendiente</option>
              <option 
                value="activo" 
                className={`${exam.data.estado === 'activo' ? "bg-blue-400": null }`} 
                selected = {exam.data.estado === 'activo'}
              >activo</option>
              <option 
                value="inactivo"
                selected = {exam.data.estado === 'inactivo'}
              >Inactivo</option>
            </select>
          }
          
        </div>
        <div className="flex flex-row my-1 justify-between">
          <p>ID Examen: </p>
          < input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-50 mx-2" placeholder={exam.data.idExamen} disabled={edit} ref={idExamen} />
        </div>
        <div className="flex flex-row my-1 justify-between">
          <p className="text-center">Plan :</p>{
            edit ? <p className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2">{exam.data.plan}</p>
            :
            <select className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2" disabled={edit} ref={plan}>
              <option value="pendiente" className={`${exam.data.plan.includes("ente")?'bg-blue-400':null }`} selected={exam.data.plan.includes("ente")}>pendiente</option>
              <option value="fast" className={`${exam.data.plan.includes("ast")?'bg-blue-400':null }`} selected={exam.data.plan.includes("ast")}>Fast</option>
              <option value="Básico" className={`${exam.data.plan.includes("sico")?'bg-blue-400':null }`} selected={exam.data.plan.includes("sico")}>Basico</option>
              <option value="Profesional" className={`${exam.data.plan.includes("fesional")?'bg-blue-400':null }`} selected={exam.data.plan.includes("fesional")}>Profesional</option>
              <option value="Premium" className={`${exam.data.plan.includes("emium")?'bg-blue-400':null }`} selected={exam.data.plan.includes("emium")}>Premium</option>
            </select>
          }
        </div>
        <details className="">
          <summary>Materias :</summary>
          {
            exam.data.materias.map((materia)=>{
              return (
                <p className="m-1 bg-gray-400 p-1 px-2 rounded">{materia}</p>
              )
            })
          }
        </details>
        <div className="flex flex-row my-1 justify-between">
        <details className="">
          <summary>Examenes :</summary>
          <button className={` text-white rounded-lg px-2 py-1 mx-2 ${edit ? "bg-gray-400":"bg-green-500"}`} disabled={edit===true}
            onClick={()=>{
              createExam(exam.data.idExamen,exam.data.intentos,exam.data.cantidadPreguntas,idUser,exam.id,exam.data.idExamen,exam.data.plan);
            }
          }
          >Generar Examenes</button>

          {
            exam.data.materias.map((materia)=>{
              return (
                <p className="m-1 bg-gray-400 p-1 px-2 rounded">{materia}</p>
              )
            })
          }
        </details>
        
        </div>

        {
          exam.data.tipo === "PFinal" &&
          <details>
            <summary className="cursor-pointer">Afiliado : <span className="text-gray-600 text-sm">Revise los datos  </span></summary>
            <p>Monto : </p>
            <input className="border-2 border-gray-400 rounded-lg px-2 w-50 mx-2" ref={monstoAfiliate}></input>
            <button className={` text-white rounded-lg px-2 py-1 mx-2 ${edit || editAfiliate ===false ? "bg-gray-400":"bg-green-500"}`} disabled={edit===true || editAfiliate===false}
            onClick={()=>{
             handleSaveAfiliate();
            }
          }
          >{editAfiliate?'Agregar':'Guardando...'}</button>
          </details>
        }

        <div className="flex flex-row justify-center items-center mt-4">
          <button className="bg-blue-400 text-white rounded-lg px-4 py-2 mx-2"
            onClick={()=>{
              setEdit(!edit);
            }}
          >Editar</button>
          <button className="bg-red-500 text-white rounded-lg px-4 py-2 mx-2">Eliminar</button>
          <button className={` text-white rounded-lg px-4 py-2 mx-2 ${edit? "bg-gray-400":"bg-green-500"}`} disabled={edit===true}
            onClick={()=>{
             handleSave();
            }
          }
          >Guardar</button>
        </div>
      </div>

    </div>
  );
};

const CardExamen = (props) =>{
  const {exam , id, idUser, codeAfiliate} = props;
  const [load , setLoad] = useState(false);

  const cantidadPreguntasPractica = useRef(null);
  const cantidadPreguntasFinal = useRef(null);

  const intentosPractica = useRef(null);
  const intentosFinal = useRef(null);

  const estadoPractica = useRef(null);
  const estadoFinal = useRef(null);

  const fechaPractica = useRef(null);
  const fechaFinal = useRef(null);

  const [plan , setPlan] = useState("pendienteee");

  let materias = [];
  exam.materias.forEach((materia)=>{
    materias.push(materia.nombre);
  });

  const handleSaveExam = async () =>{
    try{
      const cuenta = doc(db, dbRef.user + '/' + idUser);
      const newExamFinal = {
        idExamen: id,
        fechaRegistro: new Date().toISOString(),
        plan:plan,
        tipo : 'PFinal',
        nombre : exam.nombre,
        fechaExamen: fechaFinal.current.value,
        materias :materias,
        estado: estadoFinal.current.value,
        cantidadPreguntas: cantidadPreguntasFinal.current.value,
        intentos: intentosFinal.current.value,
      }
      const newExamPrueba = {
        idExamen: id,
        fechaRegistro: new Date().toISOString(),
        plan:plan,
        tipo : 'PPrac',
        nombre : exam.nombre,
        fechaExamen: fechaPractica.current.value,
        materias : materias,
        estado: estadoPractica.current.value,
        cantidadPreguntas: cantidadPreguntasPractica.current.value,
        intentos: intentosPractica.current.value,
      }

      await addDoc(collection(cuenta, 'cuenta'), newExamFinal);
      await addDoc(collection(cuenta, 'cuenta'), newExamPrueba);


      alert('Examen agregado');
    }catch(e){
      alert('Error al agregar examen');
    }
  }

  //console.log(exman);
  return (
    <div className="p-1 w-1/3 self-start">
      <div className="bg-gray-300 rounded-lg shadow-lg p-4">
        <h2 className="text-center font-bold text-base">{exam.nombre}</h2>
        <p className="text-sm text-center">{id}</p>
        <p>{plan}</p>
        <div className={`${load?'flex':'hidden'} flex-col`}>
        <div className="flex flex-row my-1 justify-between">
          <p className="text-sm text-center">Plan :</p>
          <select className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2"
            onChange={(e)=>{
              setPlan(e.target.value);
            }}
          >
            <option value="pendiente">Pendiente</option>
            <option value="Básico">Basico</option>
            <option value="Profesional">Profesional</option>
          </select>
        </div>
          <p className="text-center border-b-2 border-gray-500 w-full text-primary font-bold">Prueba Practica</p>
          <div className="flex flex-row mt-1 justify-between ">
            <p>Cantidad de preguntas : </p>
            <input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-10 mx-2" ref={cantidadPreguntasPractica}/>
          </div>
          <div className="flex flex-row mt-1 justify-between ">
            <p>Intentos : </p>
            <input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-10 mx-2" ref={intentosPractica}/>
          </div>
          <div className="flex flex-row mt-1 justify-between ">
            <p>Estado : </p>
            <select className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2" ref={estadoPractica}>
              <option value="pendiente">pendiente</option>
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>
          <div className="flex flex-row mt-1 justify-between ">
            <p>Fecha de Examen : </p>
            <input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2" placeholder="dd/m/aaaa" ref={fechaPractica}/>
          </div>

          <p className="text-center border-b-2 border-gray-500 w-full my-4  text-primary font-bold">Prueba Final</p>
          <div className="flex flex-row mt-1 justify-between ">
            <p>Cantidad de preguntas : </p>
            <input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-10 mx-2" ref={cantidadPreguntasFinal}/>
          </div>
          <div className="flex flex-row mt-1 justify-between ">
            <p>Intentos : </p>
            <input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-10 mx-2" ref={intentosFinal}/>
          </div>
          <div className="flex flex-row mt-1 justify-between ">
            <p>Estado : </p>
            <select className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2" ref={estadoFinal}>
              <option value="pendiente">pendiente</option>
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>
          <div className="flex flex-row mt-1 justify-between ">
            <p>Fecha de Examen : </p>
            <input type="text" className="border-2 border-gray-400 rounded-lg px-2 w-40 mx-2" placeholder="dd/m/aaaa" ref={fechaFinal}/>
          </div>
          <button className="bg-green-400 text-white rounded-lg px-4 py-2 m-2"
            onClick={()=>{
              handleSaveExam();
            }}
          >Guardar</button>
        </div>
        <button className="bg-blue-400 text-white rounded-lg px-4 py-2 mx-2"
          onClick={()=>{setLoad(!load)}}
        >Seleccionar</button>
      </div>
    </div>
  );
}


const Counts = (props) => {
  const [exams, setExams] = useState([]);
  const [load , setLoad] = useState(false);
  const [modal , setModal] = useState(false);
  const [dataExam , setDataExam] = useState([]);
  //props.loca
  
  useEffect(()=>{
    getExams();
  },[])

  useEffect(()=>{
    setDataExam(dataExam);
  },[dataExam])

  const getExams = async () => {
    const cuenta = doc(db, dbRef.user, props.location.id);
    await getDocs(collection(cuenta, "cuenta")).then((response) => {
      let newExams = [];
      response.forEach((doc) => {
        newExams.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      console.log(newExams);
      setExams(newExams);
    }).then(()=>{setLoad(true);console.log(exams)});
    
  }

  const handleSave = async (id, exam) => {
    setModal(!modal);
    if(dataExam.length === 0){
      alert("ya hay examenes");
      await getDocs(collection(db,dbRef.exam))
      .then((response)=>{
          response.forEach((doc)=>{
            dataExam.push({id:doc.id,data:doc.data()});
          });
      }).then( doc =>{console.log(dataExam);
        setDataExam(dataExam);
      })
    }else{
      alert("no hay examenes");
    }
  };

  return (
    <>
      <div className="flex flex-row justify-center items-start mt-16 py-8 flex-wrap w-full bg-gray-200">
        {
          exams?
          exams.map((exam)=>{
            return (
              <CardCounts exam={exam} idUser= {props.location.id}  codeAfiliate={props.location.codeAfiliate}/>
            )
          }):
          null
        }
      </div>
            {/*boton flotaante*/}
            <div className="fixed bottom-0 right-0 m-4">
        <button className="bg-green-500 text-white rounded-full px-4 py-3" onClick={()=>{handleSave()}}>Agregar Examen</button>
      </div>
      {/*modal*/}
      <div className={`fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 ${modal ? "flex":"hidden"}`}>
        <div className="bg-white w-11/12  m-auto rounded-lg shadow-lg h-90">
          <div className="flex flex-row justify-between items-center p-4">
            <h1 className="text-2xl">Agregar Examen</h1>
            <button className="bg-red-500 text-white rounded-full px-4 py-3" onClick={()=>{setModal(false)}}>X</button>
          </div>
          <div className="flex flex-row justify-center items-center p-4 flex-wrap overflow-auto h-70">
            {
              dataExam.length > 0 ?
              dataExam.map((exam)=>{
                return (
                  <CardExamen id = {exam.id} exam={exam.data} idUser= {props.location.id}/>
                )
              }):
              null
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Counts;