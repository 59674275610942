import { useEffect, useState } from 'react';
import userApriori from '../../../assets/images/aprioriUser.png';
import { Link } from "react-router-dom";

export default function Photo({userName, userLastName, userEmail, userPhoto, userMensajes,isAfiliate}) {
    const [name, setName] = useState(userName);
    const [lastName, setLastName] = useState(userLastName);
    const [photo, setPhoto] = useState(userPhoto);
    const [mensajes, setMensajes] = useState(userMensajes);
    const [email, setEmail] = useState(userEmail);
    const [afiliate, setAfiliate] = useState(isAfiliate);

    useEffect(() => {
        setName(userName);
        setLastName(userLastName);
        setPhoto(userPhoto);
        setMensajes(userMensajes);
        setEmail(userEmail);
        setAfiliate(isAfiliate);
    } , [userName, userLastName, userPhoto, userMensajes, userEmail, isAfiliate]);


    return(
        <div className='w-3/12 flex flex-col justify-center items-center pb-16 sm:hidden md:flex'>
           <img className="mt-4 border-8 border-gray-200 w-3/4" src={photo?photo:userApriori} alt="no photo"/>
            <h2 className='mt-8 text-3xl text-primary'>{name && name}</h2>
            <h3>{lastName && lastName}</h3>
            <p>{email && email}</p>
            {/* <p>{auth.currentUser.uid && auth.currentUser.uid}</p> */}
            <div className="flex flex-col justify-center items-center border border-accent mx-5 mt-5 rounded-t-xl ">
              <div className="flex flex-col justify-center bg-accent rounded-t-xl w-full py-3"><h2 className="text-center text-white">Mensajes</h2></div>
              <div className="flex flex-col justify-center items-center ">
                {
                  mensajes?
                    mensajes?
                      mensajes.map((mensaje, index) => {
                        if (index %2===0 ){
                          return <p key={index} className="text-center bg-gray-300 w-full p-2">{mensaje}</p>
                        }else{
                          return <p key={index} className="text-center bg-gray-100 w-full p-2">{mensaje}</p>
                        }
                      })
                    :
                    <p className='p-2'>No tiene mensajes</p>
                  :
                  <p className='p-2'>No tiene mensajes</p>
                }
              </div>
            </div>
            {
              afiliate?
                <Link to='/adminAfiliate' className='mt-8 bg-primary text-white p-2 rounded-xl'>Administrar Afiliado</Link>
              :
              <div className='text-center mt-4'>
                <p>Ahora puede ser un Afiliado</p>
                <Link
                  to='/adminAfiliate'
                  className='mt-8 text-blue-500 p-2'
                >
                  ¿Qué es ser un Afiliado?
                </Link>
              </div>
            }

        </div>
    )
}