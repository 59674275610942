import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { onAuthStateChanged } from "firebase/auth";
import {auth} from '../config/firebase';
import { useDispatch,useSelector } from 'react-redux';
import { isLoggedIn } from '../redux/actions/auth';
import { getUser } from '../redux/actions/user';


export default function AppRoute({ component:Component, path, isPrivate, ...props }){
    const reduxAuth = useSelector(state => state.auth);
    const reduxUser = useSelector(state => state.user);

    const dispatch = useDispatch();
    const [isLoggedIn_, setIsLoggedIn_] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [load, setLoad] = useState(false);
    
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
        if (user) {
            setIsLoggedIn_(true);
            setLoad(true);
          //  dispatch(isLoggedIn(true))
        } else {
            //dispatch(isLoggedIn(false))
            setLoad(true);
            setIsLoggedIn_(false)
        }
        });
    });

    useEffect(() => {
        if(load){
            if(isLoggedIn_){
                dispatch(isLoggedIn(true))
            }else{
                dispatch(isLoggedIn(false))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [load]);

    useEffect(() => {
        if(isLoading !== reduxAuth.isAuthenticatedLoading){
            setIsLoading(reduxAuth.isAuthenticatedLoading);
        }

        if(reduxAuth.isAuthenticated){
            if(reduxUser.user === null){
                if(auth.currentUser){
                    const userID = auth.currentUser.uid;
                    dispatch(getUser(userID));
                } else {
                    console.log('[AppRoute]:no hay usuario loggueado');
                }
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reduxAuth]);

    return(
        <Route
            exact={true}
            path={path}
            render={props => 
                isPrivate && !isLoggedIn_?(
                    isLoading?
                    <div className="bg-red-100">
                        cargando......
                    </div>:
                        <Redirect to="/login" />
                ):
                (
                    <Component {...props} />
                )
            }
            {...props}
        />
    );
}
