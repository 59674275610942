import { useEffect, useState } from "react";
import {MdEventNote, MdSource} from 'react-icons/md';
import {ImStatsDots} from 'react-icons/im';
import {BiTask,BiPhoneCall} from 'react-icons/bi';

export default function NabarPhone({active, onActive}) {
    const [windowActive, setWindowActive] = useState(active);
    useEffect(() => {
        setWindowActive(active);
    }, [active]);
    return (
        <>
        <div className='bg-primary fixed sm:fixed md:hidden mt-full flex flex-row w-full text-white w-full justify-between px-8 sm:px-3 md:px-4 rounded-b-lg'
         style={{position:'fixed',bottom:'0px',zIndex:999999 }}
         >
           <p className={`flex flex-col items-center mt-2 py-2 pb-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg  ${windowActive === 'exams'?' bg-white text-primary':'text-white'}`} 
           onClick={() => onActive('exams')}
           ><BiTask className='text-3xl'/><span className='text-xs'>Examenes</span></p>
           <p className={`flex flex-col items-center mt-2 py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'events'?' bg-white text-primary':'text-white'}`}
           onClick={() => onActive('events')}
           ><MdEventNote className='text-3xl'/><span className='text-xs'>Eventos</span></p>
           <p className={`flex flex-col items-center mt-2 py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'history'?' bg-white text-primary':'text-white'}`}
           onClick={() => onActive('history')}
           ><ImStatsDots className='text-3xl'/> <span className='text-xs'>Resultados</span> </p>
           <p className={`flex flex-col items-center mt-2 py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'sources'?' bg-white text-primary':'text-white'}`}
           onClick={() => onActive('sources')}
           ><MdSource className='text-3xl'/> <span className='text-xs'>Recursos</span> </p>
           <p className={`flex flex-col items-center mt-2 py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'contact'?' bg-white text-primary':'text-white'}`}
           onClick={() => onActive('contact')}
           ><BiPhoneCall className='text-3xl'/><span className='text-xs'>Soporte</span> </p>
         </div>
       </>
    )
}