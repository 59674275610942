import React from "react";
import Header from "../components/headerPage";
import { FaBookmark } from "react-icons/fa";
//import  Comments  from "../components/comments";
import Footer from "../components/footer";
import Title from "../components/title";
import image from "../../assets/images/service.png";
import contact from "../../assets/images/redes.png";
import { Contactanos } from "../components/uiComponents";

const CardService = (props) =>{
    return(
        <div className="flex flex-row sm:flex-col md:flex-row items-start justify-center h-auto p-5">
            <FaBookmark className="text-5xl text-primary mx-0 sm:mx-auto md:mx-0 sm:text-3xl md:text-5xl"/>
            <div className=" pl-5 sm:pl-0 md:pl-5 text-left sm:text-center md:text-left">
                <p className="text-xl font-bold text-fondo">{props.titulo}</p>
                <p className="font-regular">{props.descripcion}</p> 
            </div>            
        </div>
     );
}

const Contact = (props) =>{
    return(
        <div  className=" flex flex-col items-center my-20 sm:my-10 md:my-20" >
        <div className="flex flex-row sm:flex-col md:flex-row justify-around items-center p-8 h-auto  tracking-wider w-full" style={{backgroundImage:`url(${contact})`,backgroundPosition:'bottom'}}>
            <div className="w-7/12 sm:w-full md:w-7/12 text-left sm:text-center md:text-left">
                <h1 className="text-3xl font-bold text-primary">A<span className="text-3xl font-bold text-white">priori</span></h1>
                <p className="text-3xl text-white border-t-2 border-white mt-2 sm:text-xl md:text-3xl">
                ¿Necesitas evaluar a tu personal en algún tema específico?
                </p> 
            </div>
            
            <div className="flex flex-row justify-center w-3/12">
                <Contactanos type={'fill'} texto={'Contactanos'}/>
                
            </div>
        </div>
      </div>
     );
}





const Service = () => {
    return (
        <>
        <Header titulo={"Que hacemos"} nombre={"servicios"} mensaje={"Preparate ahora mismo."} image={image}/>
        <Title titulo="Nuestro Servicio" mensaje="¿Cómo funciona Apriori?"/>
        <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 bg-white auto-rows-min h-auto w-full px-32 sm:px-4 md:px-24 ">
            <CardService titulo="1. Encuentra la convocatoria a la que postulas." 
            descripcion="Podras ver en nuestro sitio todas las convovatorias vigentes, elige la convocatoria a la que postulas y empieza tu preparación.
            " />
            <CardService titulo="2. Prepárate en cualquier momento." 
            descripcion="Al ser usuario de Apriori, la plataforma esta disponible 24/7, solo debes elegir el momento adecuado para empezar a prepararte.
            " />
            <CardService titulo="3. Disfruta de un contenido estructurado." 
            descripcion="Nuestras pruebas siguen los parametros establecidos por su respectiva convocatoria, tales como: tiempo cronometrado y preguntas del eje temático.
            " />
            <CardService titulo="4. Comienza cuando quieras." 
            descripcion="Genera confianza con Apriori, optimiza tu tiempo y crea familiaridad con el examen que rendirás, además te mostramos los resultados y avance en tus pruebas.
            " />
        </div>
    
        <Contact/>

        <Footer/>
        
        </>
    );
}

export default Service;