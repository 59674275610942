const IMAGES = [
  {
    imageUrl: "https://loremflickr.com/600/300/paris",
    placeHolder: "Paris",
    message: "Hello comentario sobre apriori, descripcion de lo que se sube y carga a la caja de comentarios",
    autor: "John",
    date: "12.12.2019",
    cargo: "presidente",
  },
  {
    imageUrl: "https://loremflickr.com/600/300/dog",
    placeHolder: "Dog",
    message: "comentario sobre apriori, descripcion de lo que se sube y carga a la caja de comentarios",
    autor: "John",
    date: "12.12.2019",
    cargo: "presidente",
  },
  {
    imageUrl: "https://loremflickr.com/600/300/cat",
    placeHolder: "Cat",
    message: "Hello comentario sobre apriori, descripcion de lo que se sube y carga a la caja de comentarios",
    autor: "John",
    date: "12.12.2019",
    cargo: "presidente",
  },
  {
    imageUrl: "https://loremflickr.com/600/300/forest",
    placeHolder: "Forest",
    message: "Hello comentario sobre apriori, descripcion de lo que se sube y carga a la caja de comentarios",
    autor: "John",
    date: "12.12.2019",
    cargo: "presidente",
  },
  {
    imageUrl: "https://loremflickr.com/600/300/cat",
    placeHolder: "Cat",
    message: "Hello comentario sobre apriori, descripcion de lo que se sube y carga a la caja de comentarios",
    autor: "John",
    date: "12.12.2019",
    cargo: "presidente",
  },
  {
    imageUrl: "https://loremflickr.com/600/300/forest",
    placeHolder: "Forest",
    message: "Hello comentario sobre apriori, descripcion de lo que se sube y carga a la caja de comentarios",
    autor: "John",
    date: "12.12.2019",
    cargo: "presidente",
  },
];
export default IMAGES;