import React,{useState, useEffect} from "react";
import Logo from "../../assets/images/logo.png";
import {FaCheckCircle} from "react-icons/fa";
import {CgRadioCheck} from "react-icons/cg";
import {FaRegShareSquare,FaRegEye} from "react-icons/fa";
import {RiWhatsappFill, RiFacebookBoxFill,RiEyeCloseFill} from "react-icons/ri";
import {GiRotaryPhone} from "react-icons/gi";
import {SiGooglemaps} from "react-icons/si";
import logo from "../../assets/images/logo.png";
import {BiDownArrow , BiUpArrow} from "react-icons/bi";
function Entidad (props){
  const {name, schedule, textSchedule, description, whatsapp, phone,facebook , map, address, children} = props;

  const [isOpen, setIsOpen] = useState(false);
  const [visible , setVisible] = useState(false);
  //Descripcion de la escula planes de servicios de la escuela de conduccion acreditada por el SEGIP y el costo del plan de servicios, ademas de los requisitos para adquirir el plan de servicios.


  const horarioCar = schedule
  /*{
    manianaInicio: 8,
    manianaFin: 12,
    tardeInicio: 14,
    tardeFin: 18,
  }*/

  useEffect(() => {
    //obtner la hora actual
    const date = new Date();
    const hour = date.getHours();
    //obtener dia actual en numero
    const day = date.getDay();
    console.log(day);
    //carburador car
    if ((hour >= horarioCar.manianaInicio && hour <= horarioCar.manianaFin)||(hour >= horarioCar.tardeInicio && hour <= horarioCar.tardeFin) && day !== schedule.day1 &&  day !== schedule.day2 ) {
      setIsOpen(true);
    } else{
      setIsOpen(false);
    }
  } , []);


  return (
    <div className="w-11/12">
      <h4 className="font-bold text-red-600 uppercase flex flex-row sm:flex-col md:flex-row items-start w-11/12">
        {name}
      </h4>
      <p className={`${isOpen?'text-green-500':'text-red-500'} text-sm`}>
        Horario : {textSchedule}
        <span>
            {isOpen?'(abierto)':'(cerrado)'}
        </span>
      </p>
      <span 
        className="text-sm normal-case font-normal ml-2 text-white bg-green-500 flex flex-row items-center justify-center mr-2 border-2 border-green-700 rounded p-1 text-center m-2"
        onClick={() => {setVisible(!visible)}}
        >
          {
            !visible?
            "ver detalles": "ocultar detalles"
          }
          {
            !visible?<FaRegEye className="ml-2"/> 
            :<RiEyeCloseFill className="ml-2"/>
          }
           
      </span>
      <div className={`${visible?'flex':'hidden'} flex-col items-start mb-8 w-full`}>
        {
          description && <p className="text-justify">{description} </p> 
        }
      
      {children}
      <p className="mt-2 text-justify font-bold ">Contactos :</p>
      <div  className="flex flex-row sm:flex-col sm:items-start ml-2 items-center justify-around 
      w-full mt-2">
        {
          whatsapp&&
          <a className="flex flex-row items-center text-green-600"
          href={`https://wa.me/591`+whatsapp}
        >
          <RiWhatsappFill/> {whatsapp}</a>
        }
        
        {
          phone&&<a  className="flex flex-row items-center text-red-700"
          href={`tel:`+phone}
        >
          <GiRotaryPhone/>
          {phone}
        </a>
        }
        {
          facebook&&<a  className="flex flex-row items-center text-blue-700 w-1/2"
          href={`https://www.facebook.com/`+facebook}
        >
          <RiFacebookBoxFill/>
          {facebook}
        </a>
        }
      </div>
      <div  className="flex flex-row items-center justify-center  w-full">
        
        <a 
          className="flex flex-row items-center bg-blue-500 px-4 py-1 mt-4 rounded text-white"
          href={map}//https://goo.gl/maps/pyx4oRagp6P3xM3X8"
          target="_blank" rel="noopener noreferrer"
        >
            <SiGooglemaps size={36} 
  className="text-green-500"  color={"#fff"}/>
          <span className="flex flex-col items-left ml-4">
            Como llegar ?
            <p className="text-sm">{address}</p>
          </span>  
            </a>
        </div>
      </div>
    </div>
  )
}

function Item(props){
  const {active,text, children, description, childrenDescription} = props;
  const [isOpen, setIsOpen] = useState(active);
  const [visible , setVisible] = useState(children?true:false);
  const [seeDatails , setSeeDatails] = useState(false);

  useEffect(() => {
    console.log(isOpen);
  } , [isOpen]);
  return (
    <div className="flex flex-row justify-start items-start border-gray-300 border-b-2 p-2 my-2 w-full">
      <div className="w-10">
        {
          isOpen?
          <FaCheckCircle 
            className="text-green-500" 
            size={24}
            onClick={() => {setIsOpen(!isOpen)}}
          />
          :
          <CgRadioCheck 
            className="text-gray-500" 
            size={24}
            onClick={() => {setIsOpen(!isOpen)}}
          />
        }
        
      </div>
      <div className="ml-1">
        <span className="text-normal font-semibold">{text}</span>
        {
          description && <p className="text-justify text-sm mt-2">{description} </p>
        }
        {
          childrenDescription && childrenDescription()
        }
        
        {
          visible&&
          <div 
            className="flex flex-row items-center text-green-600 cursor-pointer mt-2 font-normal"
            onClick={() => {setSeeDatails(!seeDatails)}}
          >
            <p className="text-normal border-2 border-green-500 rounded flex flex-row items-center p-1 pl-3 ">{seeDatails?'Ocultar':'Ver'} como obtener   
              {seeDatails?<BiUpArrow className="mx-4"/>:<BiDownArrow className="mx-4"/>}
            </p>
          </div>
        }
        {
          seeDatails&& children
        }
      </div>
    </div>
  )

}

const Driver = () => {
  const bar = document.getElementById('bar');
  if (bar) {
    bar.style.display = 'none';
  }

  const [isOpen, setIsOpen] = useState(false);
  const [start , setStart] = useState(true);
  const [escCar, setEscCar] = useState(false);
  const [escMotor, setEscMotor] = useState(false);
  const [transito , setTransito] = useState(false);
  const [detallesCar , setDetallesCar] = useState(false);
  const [detallesMotor , setDetallesMotor] = useState(false);
  const [detallesTransito , setDetallesTransito] = useState(false);

  useEffect(() => {
    console.log(isOpen);
  } , []);

  
  return (
    <>
      <div className={`${start?'fixed snap-none ':'hidden'} top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 z-50 flex flex-col items-center justify-center`}>
        <div className="bg-fondo w-11/12 md:w-1/2 lg:w-1/3 h-5/6 rounded-lg shadow-lg flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-between w-full px-4 py-2">
            <img src={logo} alt="logo" className="w-2/3"/>
            <h1 className="text-2xl font-bold text-primary text-center w-full m-2 text-white">Te ayudamoos <span className="text-primary">AHORA</span> </h1>
            <p className="text-center text-white">
              Somos una empresa que brinda servicios de asistencia en diferentes áreas,
              como lo son: asistencia vehicular, asistencia en tránsito, asistencia en viajes,
              asistencia en salud, asistencia en hogar, asistencia en viajes, asistencia en viajes,
            </p>
            <button className="bg-primary text-white font-bold py-2 px-4 rounded mt-4" onClick={() => {setStart(!start)}}>
              Iniciar
            </button>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-fondo flex flex-row justify-around items-center p-4">
        <a className="navbar-brand" href="#">
          <img src={Logo} width="100" height="100" alt=""/>
        </a>
        <h1 className="text-white text-center text-xl">Requisitos para Licencia</h1>
        <div class="relative inline-block text-left">
          <div>
            <button 
              type="button" 
              className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" 
              id="menu-button" 
              aria-expanded="true" 
              aria-haspopup="true"
              onClick={() => {setIsOpen(!isOpen)}}
              >
              Cambiar
              <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          
          <div className={`origin-top-right ${isOpen?'absolute':'hidden'} right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div className="py-1" role="none">
              <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Edit</a>
            </div>
            <div class="py-1" role="none">
              <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Move</a>
            </div>
          </div>
        </div>
      </nav>
      <div className="fixed bottom-0 right-0 m-4">
        <button className="bg-green-400 text-white font-bold py-2 px-4 rounded-full">
          todo ok
        </button>
      </div>

      <div className="p-1">
        <h1 className="text-center text-xl">Requisitos para Licencia de conducir nuevos</h1>
        <p className="text-center text-gray-700 mx-8 text-sm">
          Los requisitos de acontinuacion son para adquirir una licencia de conducir por primera vez.
        </p> 
        <p className="text-center text-gray-700 text-sm">
        Apto para categorias : <strong className="text-gray-700">"M","P","T" y "A"</strong></p>
        <p className="text-center text-gray-700  text-sm">
          Los solicitantes deben ser mayores de edad
        </p>
        <p className="text-center text-gray-700  text-sm">
          Los solicitantes deben ser mayores de edad
        </p>    
        <div className="flex flex-col justify-start items-start mt-4  mx-2  border-red-700 border-t-2 pt-8">
          <Item
            active={true} 
            text={'Cedula de identidad original vigente y consolidado por el SEGIP.'} 
            description={'La cédula de identidad debe ser original, vigente y consolidado por el SEGIP. La cédula de identidad debe ser original, vigente y consolidado por el SEGIP.'}
            />
          <Item 
          text={'Certificado de habilidades para conducir original.'}
          description = {'El certificado de habilidades para conducir debe ser obtenido en una de las instituciones autorizadas, en los que se mide dos habilidades:'}
          childrenDescription={
              ()=>{
                return (
                  <ul className="list-disc">
                    <li>
                      <p className="text-sm mt-1">
                        <b>Habilidades practicas de conduccion.-</b> en este examen se evalua la capacidad del aspirante para conducir un vehiculo en condiciones normales de trafico.
                      </p>
                    </li>
                    <li>
                    <p className="text-sm mt-1">
                      <b>Habilidades teoricas de conduccion.-</b> en este examen se evalua al aspirante en normas de trafico, señales de transito, primeros auxilios, etc.
                    </p>
                    <button className="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded my-4">
                      Examen de Prueba
                    </button>
                  </li>
                </ul>

              )
            }
        }
        >
          <div className="p-4">
            <h3 className="font-bold">
              Escuelas de conduccion acreditadas por el SEGIP.
            </h3>
            <ul>
              <li type="disc" className="text-gray-700">
              <Entidad 
              //name, schedule, textSchedule, description, whatsapp, phone,facebook , map, address, children
                name = "CARBURANDO CARS"
                schedule = {{manianaInicio: 8, manianaFin: 12, tardeInicio: 14, tardeFin: 18,day1:6,day2:0}}
                textSchedule = "Lun - Sab 08:00 a 12:00 - 14:00 a 18:00"
                description = "Descripcion de la escula planes de servicios de la escuela de conduccion acreditada por el SEGIP y el costo del plan de servicios, ademas de los requisitos para adquirir el plan de servicios."
                whatsapp = "3197998899"
                phone = "3197998899"
                facebook = "carburandocars"
                map = "https://goo.gl/maps/pyx4oRagp6P3xM3X8"
                address = "Av. Jaime Mendoza # 555"
                /> 
                </li>
              
              <li type="disc" className="text-gray-700">
              <Entidad
                name = "CARBURANDO motors"
                schedule = {{manianaInicio: 8, manianaFin: 12, tardeInicio: 14, tardeFin: 18,day1:6,day2:0}}
                textSchedule = "Lun - Vie 08:00 a 12:00 - 14:00 a 18:00"
                description = "Descripcion de la escula planes de servicios de la escuela de conduccion acreditada por el SEGIP y el costo del plan de servicios, ademas de los requisitos para adquirir el plan de servicios."
                whatsapp = "3197998899"
                phone = "3197998899"
                facebook = "carburandocars/"
                map = "https://goo.gl/maps/pyx4oRagp6P3xM3X8"
                address = "Av. Jaime Mendoza # 55"

              />
              </li>
              <li type="disc" className="text-gray-700">
              <Entidad
                name = "Transito"
                schedule = {{manianaInicio: 8, manianaFin: 12, tardeInicio: 14, tardeFin: 18,day1:6,day2:0}}
                textSchedule = "Lun - Vie 08:00 a 12:00 - 14:00 a 18:00"
                description = "Descripcion de la escula planes de servicios de la escuela de conduccion acreditada por el SEGIP y el costo del plan de servicios, ademas de los requisitos para adquirir el plan de servicios."
                whatsapp = "3197998899"
                phone = "3197998899"
                facebook = "carburandocars/"
                map = "https://goo.gl/maps/pyx4oRagp6P3xM3X8"
                address = "Av. Jaime Mendoza # 55"
              />
              </li>
            </ul>
          </div>
        </Item>

        <Item text={'Certificado Médico original.'}>
        <div className="p-4">
            <h3 className="font-bold">
              Centros Médicos acreditadas por el SEGIP.
            </h3>
            <ul>
              <li type="disc" className="text-gray-700">
              <Entidad
                name = "Cies"
                schedule = {{manianaInicio: 8, manianaFin: 12, tardeInicio: 14, tardeFin: 18,day1:6,day2:0}}
                textSchedule = "Lun - Sab 08:00 a 12:00 - 14:00 a 18:00"
                description = "Descripcion de la escula planes de servicios de la escuela de conduccion acreditada por el SEGIP y el costo del plan de servicios, ademas de los requisitos para adquirir el plan de servicios."
                whatsapp = "3197998899"
                phone = "46440561"
                facebook = "Cies"
                map = "https://goo.gl/maps/XUC9cePF1dDq84Gt5"
                address = "Av. Venezuela # 1001"
              />
              </li>
              <li type="disc" className="text-gray-700">
                <Entidad
                  name = "Cimes"
                  schedule = {{manianaInicio: 8, manianaFin: 12, tardeInicio: 14, tardeFin: 18,day1:6,day2:0}}
                  textSchedule = "Lun - Sab 08:00 a 12:00 - 14:00 a 18:00"
                  description = "Descripcion de la escula planes de servicios de la escuela de conduccion acreditada por el SEGIP y el costo del plan de servicios, ademas de los requisitos para adquirir el plan de servicios."
                  whatsapp = "3197998899"
                  phone = "46460444"
                  facebook = "cimesfb"
                  map = "https://goo.gl/maps/tkrxczPDGP7pYfEh7"
                  address = "Calle Nataniel Aguirre # 641"
                />
              </li>
              <li type="disc" className="text-gray-700">
                <Entidad
                  name = "Clinica copacabana (Transito)"
                  schedule = {{manianaInicio: 8, manianaFin: 12, tardeInicio: 14, tardeFin: 18,day1:6,day2:0}}
                  textSchedule = "Lun - Sab 08:00 a 12:00 - 14:00 a 18:00"
                  description = "Descripcion de la escula planes de servicios de la escuela de conduccion acreditada por el SEGIP y el costo del plan de servicios, ademas de los requisitos para adquirir el plan de servicios."
                  whatsapp = "3197998899"
                  phone = "46434463"
                  facebook = "copacabanacol"
                  map = "https://goo.gl/maps/oagqUSSTgxgFxsmT9"
                  address = "Calle Loa # 1070"
                />
              </li>
            </ul>
          </div>
        </Item>
        <Item text={'Certificado de Antecedentes de transito, FELCC y FELCN.'} />
        <Item text={'Constancia de Depósito Bancario.'} />
        
      </div>
      </div>
    </>
  );
}

export default Driver;