import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addTest,remplaceTest } from "../../redux/actions/test";


export default function Licencias(){
    
    const test = useSelector(state => state.test.test);
    const dispatch = useDispatch();

    const handleAddTest = (id, opcionMarcada) => {
      test.map((item) => {
          if(item.id === id){
              item.opcionSeleccionada = opcionMarcada;
          }
      });
      dispatch(remplaceTest(test));
    }
    
    return (
        <div className="m-24">
            <h1>Licencias</h1>
        </div>
    );
};
