import types from '../types/test';

const initialState = {
    test: [],
    error: 'a',
};

export const testReducer =  (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_TEST_FAILURE:
            return {
              ...state,
              error: true,
            };
        case types.ADD_TEST_SUCCESS:
            return {
              ...state,
              test: state.test.concat(action.payload),
              error: false,
            };
        case types.REMPLACE_TEST_FAILURE:
            return {
              ...state,
              error: true,
            };
        case types.REMPLACE_TEST_SUCCESS:
            return {
              ...state,
              test: action.payload,
              error: false,
            };
        default: 
            return{
              ...state,
              error: null,
            } 

    }
}