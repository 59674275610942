import React, {Fragment, useState, useEffect} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { login_, startClearError } from '../../redux/actions/auth';
import {Redirect} from 'react-router-dom';
import Modal from '../components/Modal';

import {useForm} from 'react-hook-form';
//link  to Profile
const Form =  () => {
    const reduxAuth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors } } = useForm();
    //const [user , setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const closeErrorModal = () => {
        setErrorModal(false);
        dispatch(startClearError());
    }

    const onSubmit = (data, e) => {
        dispatch(login_(data));
    };

    useEffect(() => {
        if(reduxAuth.error){
            console.log('hay un error de loggin');
            setErrorModal(true);
        }
        setIsLoading(reduxAuth.loading);
        setIsAuthenticated(reduxAuth.isAuthenticated);

    }, [reduxAuth]);

    return(
        <Fragment>
            {isAuthenticated?<Redirect to="/profile" />:null}
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-center w-full">
            <h1 className=" text-red-500 text-center">Inicio de sesion</h1>
                <input
                    icon="user"
                    name="user"
                    placeholder="Correo electronico"
                    type="email"
                    className="my-3 p-2 border-2 focus:border-0 focus:border-red-0 focus:border-red-300"

                    {...register("user", {required:true})}
                    
                />
                <input
                    name="password"
                    type="password"
                    
                    placeholder="Contraseña"
                    className="my-3 p-2 border-2 focus:border-0 focus:border-red-0 focus:border-red-300"
                    {...register("password", {required:true})}
                />
                {errors.password?.type === 'required' && "Debe introducir una contraseña"}
                {errors.user?.type === 'required' && "Email esta vacio"}

                {!isLoading?<button className="py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-transparent hover:text-primary">
                    iniciar sesion
                </button>:
                <button className="py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary" disabled>
                    <center>
                    <div className='flex'>
                        <div className="mr-1">cargando</div>
                        <div className={`h-2.5 w-2.5 bg-current rounded-full mr-1 animate-bounce`}></div>
                        <div className={`h-2.5 w-2.5 bg-current rounded-full mr-1 animate-bounce200`}></div>
                        <div className={`h-2.5 w-2.5 bg-current rounded-full mr-1 animate-bounce400`}></div>
                    </div>
                    </center>
                </button>
                }
                {/* <p>
                Password:{
                    user?user.password?user.password:'':''
                }
                </p>
               
                <p> 
                Usuario:{
                    user?user.user?user.user:'':''
                }
                </p> */}
    
      
            </form>
            <Modal
            visible={errorModal}
            title="Ocurrio un error"
            onClose={()=>closeErrorModal()}
            >
                <div className="mt-2">
                <p className="text-sm text-gray-1000">
                    Error al iniciar sesión, Verifique sus datos e intentelo nuevamente
                </p>
                </div>
                <div className="mt-4">
                <button
                type="button"
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-100 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                onClick={()=>closeErrorModal()}
                >
                Aceptar
                </button>
                </div>
            </Modal>
        </Fragment>
    )
}

export default Form;
