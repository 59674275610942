import React,{useState,useEffect} from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactGa from 'react-ga';
import {useSelector} from 'react-redux';
import logoImg from '../../assets/images/aprioriblanco.png';

export default function Banner(props){
    const reduxUser = useSelector(state => state.user);
    const [user, setUser] = useState({});
    const {tipo, idEvento} = props;
    const [dateEvent, setDateEvent] = useState(new Date(props.hora));
    const [cronometro, setCronometro] = useState({dia:'',hora:'',minuto:'',segundo:''})

    var end = new Date(props.hora)
    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;


    function showRemaining() {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {
            return;
        }
        setCronometro({
            dia:Math.floor(distance / _day),
            hora:Math.floor((distance % _day) / _hour),
            minuto:Math.floor((distance % _hour) / _minute),
            segundo:Math.floor((distance % _minute) / _second)
        })
    }
    useEffect(()=>{
        let timer = setInterval(() => showRemaining(), 1000);
        // console.log('timer')
        return () => {
            // console.log(' se cerro timer')
            clearInterval(timer);
        }
    });

    const buttonAnalytics = (btn) => {
        ReactGa.event({
            category: 'Button',
            action: 'Click button '+btn+' en home ',
        })
    }

    useEffect(() => {
      setUser(reduxUser);
      console.log(reduxUser)
    }, [reduxUser])
    useEffect(() => {
        setDateEvent(new Date(props.hora));
        // console.log(dateEvent);
        // console.log(nowState);
    }, [props.hora])

    return(
    <>
        <div className="h-screen w-full background-banner text-white flex justify-center sm:justify-center md:justify-center items-center sm:items-center lg:items-center md:items-center flex-col  pl-10 px-0 sm:px-5 md:px-0 lg:pl-0 md:pl-0 bg-center">
            <p className="text-center sm:text-center md:text-start mx-0 text-2xl">Tu Futuro esta a punto de cambiar con Nosotros.</p>
            
            {
                tipo === 'evento' ?<p className="text-4xl sm:text-3xl lg:text-3xl mt-4 font-bold">Próximo Evento</p>:
                <p className="text-4xl sm:text-3xl lg:text-3xl mt-4 font-bold">Próximo Examen</p>
            }
            {/* <h1 className="text-8xl sm:text-3xl lg:text-3xlt">Area Juridico:</h1> */}
            <h1 className={`text-4xl  sm:text-2xl ${props.titulo.lenght>30?'lg:text-6xl':'lg:text-4xl'} text-primary w-1/2 sm:w-full md:w-1/2 sm:text-center text-start md:text-center lg:text-center`}>{props.titulo}</h1>
            <div className="flex mt-5">
              {
                new Date() < dateEvent ?
                <>
                  <div><div className="text-6xl  flex flex-col items-center  sm:text-3xl lg:text-6xl ">{cronometro.dia}:</div><div>Días</div></div>
                  <div className="ml-2 flex flex-col items-center "><div className="text-6xl ml-2  sm:text-3xl lg:text-6xl ">{cronometro.hora}:</div><div>Horas</div></div>
                  <div  className="ml-2 flex flex-col items-center"><div className="text-6xl ml-2  sm:text-3xl lg:text-6xl ">{cronometro.minuto}:</div><div>Minutos</div></div>
                  <div className="ml-2 flex flex-col items-center"><div className="text-6xl ml-2 sm:text-3xl lg:text-6xl ">{cronometro.segundo}</div><div>Segundos</div></div>
                </>
                :
                <div className='flex flex-col text-center'>
                <p className="text-4xl font-bold">Exámen Finalizado{
        
                }</p>
                <p className="text-base">
                  Estamos preparando todo para la próxima convocatoria.
                </p>
                </div>
                
              }  
            </div>
            <div className="flex mt-5 flex-col-reverse items-center sm:flex-col-reverse  sm:items-center lg:flex-row" >
                <Link onClick={()=>buttonAnalytics('Convocatorias')} className="text-center base sm:base lg:base  md:inline-block cursor-pointer hover:text-gray-500 py-2 rounded-full px-3 bg-white text-black mx-4 mt-0 sm:mt-3 lg:mt-0 " to="/convocatoria">Convocatorias</Link>
                {
                    tipo === 'evento' ?
                    <Link
                    onClick={()=>buttonAnalytics('Ver Detalles')}
                        className=" mx-3 text-center base sm:base lg:base cursor-pointer hover:text-gray-500 py-2 rounded-full px-3  bg-primary flex flex-row items-center" 
                        to={`/events/${idEvento}`}
                    >Ver Detalles< FaAngleRight  className="text-white "/></Link>:
                    <Link 
                    onClick={()=>buttonAnalytics('Ver Detalles')}
                    className="mx-3 text-center base sm:base lg:base cursor-pointer hover:text-gray-500 py-2 rounded-full px-3  bg-primary flex flex-row items-center hover:bg-white hover:text-fondo" to={{
                        pathname: `/Details`,
                        data: props.data
                    }}>Prueba Gratis Ahora.</Link>
                }

            </div>
            {
              user.user===null?
              <Link className="text-center base sm:base lg:base  md:inline-block cursor-pointer hover:text-gray-500 py-2 rounded-full border-2 border-primary px-3 text-primary mt-4 text-white mx-4 mt-0 sm:mt-3 lg:mt-4 " to="/login">Iniciar Sesión </Link>
              :
              <Link className="text-center base sm:base lg:base  md:inline-block cursor-pointer hover:text-gray-500 py-2 rounded-full border-2 border-primary px-3 text-primary mt-4 text-white mx-4 mt-0 sm:mt-3 lg:mt-4 " to="/profille">Ir a Perfil</Link>
            }
            
        </div>
        <div className="bg-fondo px-12 py-4 text-xl flex justify-center flex-col items-center">
            <img className='w-32  my-2' src={logoImg}/>
            <p  className="text-white text-center font-normal text-xl sm:text-sm md:text-xl my-2">
                {/* +<span>Apriori</span> es una plataforma de aprendizaje online que te ayudara a mejorar tu nivel de ingles. */}
                Nuestra plataforma está diseñada de forma tan minuciosa que te brindará una experiencia en la que obtendrás mayor seguridad y confianza.
            </p>
            {/* <h1 className='text-primary my-4'>Nuestras Areas de accion:</h1>
            <div className="flex flex-row w-1/2 justify-around  text-white text-center ">
                <div className='flex flex-col items-center mx-2' >
                    <GiInjustice className="w-12 h-12 mr-2"/>
                    <p className='font-medium text-white text-xl sm:text-sm'>Jurídico</p>
                </div>
                <div className='flex flex-col items-center mx-2'>
                    <AiFillMedicineBox  className="w-12 h-12 mr-2"/>
                    <p className='font-medium  text-white text-xl sm:text-sm'>Medicina</p>
                </div>
                
                <div className='flex flex-col items-center mx-2'>
                    <FaChalkboardTeacher  className="w-12 h-12 mr-2"/>
                    <p className='font-medium  text-white text-xl sm:text-sm'>Profesores</p>
                </div>
                <div className='flex flex-col items-center mx-2'>
                    <GiPoliceBadge className="w-12 h-12 mr-2 text-white"/>
                    <p className='font-medium text-white text-xl sm:text-sm'>Policial</p>
                </div>
            </div> */}
        </div>
    </>
    );
};
