import React from "react";
import Header from "../components/headerPage";
import { FaFacebook, FaInstagram  } from "react-icons/fa";
import {IoIosGlobe} from "react-icons/io";
import {MdDesignServices} from "react-icons/md";
import {RiUser5Line} from "react-icons/ri";
import {HiDesktopComputer} from "react-icons/hi";

// import 

import  Comments  from "../components/comments";
import Footer from "../components/footer";
import Title from "../components/title";
import image from "../../assets/images/team.png";
import redes from "../../assets/images/redes.png";
import banner from "../../assets/images/banner.png";
import leo from "../../assets/images/team/leo.png";
import abi from "../../assets/images/team/abi.jpg";
import rimber from "../../assets/images/team/rimber.jpg";


function Card(props) {
    return (
        <div class=" m-8 bg-gray-400 flex justify-center items-center">
        <div class="flex flex-col justify-between w-72 sm:w-96 h-96 bg-white bg-center text-gray-800 shadow-md overflow-hidden cursor-pointer" 
            style={{
            backgroundImage: `url(${props.foto})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundFilter: "grayscale(100%)"

            
            }}>
            <div class="flex justify-between items-center ml-4 pr-8">
            <div class="bg-primary text-white bg-opacity-95 shadow px-2 py-1 flex items-center font-bold text-xs rounded"></div>
            <div class="bg-primary w-10 h-12 shadow flex flex-col-reverse p-2 text-center font-bold text-white rounded-b-full">
            {
              props.icon === 'ceo' ? <RiUser5Line className="text-2xl text-center"/> :
              props.icon === 'design' ? <MdDesignServices className="text-2xl text-center"/>:
              <HiDesktopComputer className="text-2xl text-center"/>
            }

            </div>
            </div>
            <div class="bg-white bg-opacity-95 shadow-md rounded-r-xl p-4 flex flex-col mr-4 mb-2">
                <h3 class="text-xl font-bold pb-2">{props.nombre}</h3>
                <h3 class="text-xl text-primary font-bold pb-2">{props.cargo}</h3>
                <p class="text-gray-500 text-xs">{props.descripcion}</p>
            
            </div>
        </div>
        </div>

        // <div className={` grayscale flex items-center justify-center w-1/3 sm:w-full md:w-1/3 sm:flex-col md:flex-row`}>
        //   <div className="h-full w-full m-4" style={{
        //     backgroundImage: `url(${props.foto})`,
            
        //     contain:' ',
        //     backgroundSize:'cover',
        //     //filter: 'grayscale(100%)',
        //     minHeight:'450px',
        //   }}>
        //     <img src={props.foto} alt=""/>
        //     <div className="bg-fondo h-full w-full px-5 py-8 sm:text-center md:text-left text-left">
        //         <h1 className="text-2xl mt-2 font-bold text-white">{props.nombre}</h1>
        //         <p className="font-base text-white">{props.cargo}</p> 
        //         {/* <a className="font-sm text-white" href={`mailto:${props.correo}`}>{props.correo}</a>
        //         <p className="font-base text-white mt-5">{props.descripcion}</p> */}
        //     </div>
        //   </div>
          
         
        // </div>
    );
}
//crear componente funcinal
const Team = () => {
    return (
        <>
        <Header titulo={"Quienes somos"} nombre={"Nosotros"} mensaje={"Tu Empresa de Aprendizaje"} image={image} />
        <div className="bg-white w-full py-20 px-32 sm:px-4 md:px-32">
            <div className="flex flex-row justify-around sm:flex-col md:flex-row text-left sm:text-center md:text-left">
                <div className="w-1/2 pr-32 sm:w-full sm:pr-0 md:w-1/2 md:pr-32">
                    <h1 className="text-primary">Quienes somos</h1>
                    <p  className="my-5 font-bold text-3xl pr-10 text-justify sm:text-center md:text-justify sm:pr-0 md:pr-10">
                    <span className="text-primary" >A</span>priori una plataforma pionera.
                    </p>
                    <p className="text-justify">
                        Enfocada en la preparación de postulantes a convocatorias que exigen un examen de competencias.
                    </p>
                    <p className="text-justify">
                        Somos un equipo multidisciplinario de profesionales que se especializan en el desarrollo de soluciones tecnológicas para la gestión de las convocatorias.
                    </p>
                    {/* <Link >as</Link> */}
                    <button className="bg-primary text-white py-2 px-4 rounded-full mt-5">Inicia ahora</button>
                </div>
                <div className="bg-equipo bg-cover w-1/2 sm:w-0 md:w-1/2" style={{backgroundImage:`url(${banner})`}}></div>
            </div>
            {/* <div  className="flex flex-row justify-between mt-14" >
                <div>
                    <h1 className="text-2xl"><span  className="text-4xl text-primary mb-8">01.</span> mision</h1>
                    <p className="mt-3">la mision de apriori es la mision de apriori cumpliendo la mision que todos tenemos" osea aqui la mision"</p>
                </div>
                <div>
                    <h1 className="text-2xl"><span className="text-4xl text-primary mb-8">02.</span> vision</h1>
                    <p className="mt-3">la mision de apriori es la mision de apriori cumpliendo la mision que todos tenemos" osea aqui la mision"</p>
                </div>
                <div>
                    <h1 className="text-2xl"><span className="text-4xl text-primary mb-8">03.</span> enfoque</h1>
                    <p className="mt-3">la mision de apriori es la mision de apriori cumpliendo la mision que todos tenemos" osea aqui la mision"</p>
                </div>
            </div> */}
        </div>
        <div className="flex flex-row justify-around w-full p-8" style={{backgroundImage:`url(${redes})`,backgroundPosition:'bottom'}}>
            <div className="flex flex-col items-center">
                <FaFacebook className="text-3xl text-primary"/>
                <h1 className="text-5xl mt-2 text-white">600+</h1>
                <p className="text-white">Facebook</p>
            </div>
            <div className="flex flex-col items-center">
                <FaInstagram className="text-3xl text-primary"/>
                <h1 className="text-5xl mt-2 text-white">60+</h1>
                <p className="text-white">Instagram</p>
            </div>
            <div className="flex flex-col items-center text-primary">
                <IoIosGlobe className="text-3xl text-primary"/>
                <h1 className="text-5xl mt-2 text-white">8000+</h1>
                <p className="text-white">Visitas Web</p>
            </div>
        </div>
        <Title titulo="Nuestro Equipo" mensaje="Miembros del equipo de expertos para obtener el mejor servicio"/>
        <div className="w-full bg-gray-900 flex flex-row flex-wrap justify-center">
            <Card foto={abi} icon={'ceo'} nombre={'Abigail Mamani'} cargo={'CEO'} correo={'abigailmam95@gmail.com'} descripcion={'Administradora de empresas, parte de diferentes programas para el fortalecimiento empresarial de negocios presentes en Bolivia.'} />
             <Card foto={rimber} icon={'web'} nombre={'Rimber R. Uyuquipa Mamani'} cargo={'CTO'} correo={'rimber.re95@gmail.com'} descripcion={'Mi motivación ha sido siempre aprender tecnologias nuevas que me ayuden a resolver problemas tecnológicos con métodos vanguardistas.'} />
            <Card foto={leo} icon={'design'} nombre={'Leonel Avendaño Villarroel'} cargo={'CTO'} correo={'leonel.sis12@gmmail.com'} descripcion={'Apasionado de la tecnología y de como la misma podria cambiar la realidad y prometer nuevas alternativas.'} />
        </div>

        <Comments />
        <Footer/>
        
        </>
    );
}

export default Team;