import React from 'react';

export default function NotFound() {
    return (
        <div className="not-found">
        <h1>404</h1>
        <h2>Page not found</h2>
        <h1>404</h1>
        <h2>Page not found</h2>
        <h1>404</h1>
        <h2>Page not found</h2>
        <h1>404</h1>
        <h2>Page not found</h2>
        <h1>404</h1>
        <h2>Page not found</h2>
        </div>
    );
    }