import React ,{useState,useEffect} from 'react';
import { RiSendPlane2Fill} from "react-icons/ri";
import {marcarRespuestaTest} from './../../../redux/actions/exam';
import {useDispatch, useSelector} from 'react-redux';
import { remplaceTest } from "./../../../redux/actions/test";

const Opcion = (props) => {
  const {correcta} = props;
  const [estado,setEstado] = useState(props.active);
  useEffect(()=>{
    setEstado(props.active);
  },[props.active])


  return(
    estado === false ?
    <div className='flex flex-row items-start justify-start border-primary border-0 mt-2 pl-4 rounded cursor-pointer w-full'
      onClick={()=>{props.onPress()}}
    >
      <RiSendPlane2Fill size={32} className='mr-2 mt-2 text-gray-800' style={{maxWidth:20,minWidth:19}}/>
      
      {correcta? 
      (<>
        {
          correcta===props.dato?
          <>
          <p className='text-left text-lg sm:text-base md:text-lg text-green-200 my-2 ml-4'>
            {props.dato}
          </p>
          </>:
          <>
          <p className='text-left text-lg sm:text-base md:text-lg text-fondo my-2 ml-4'>
            {props.dato}
          </p>
          </>
        }
      </>):
      (<>
        <p className='text-left text-lg sm:text-base md:text-lg text-fondo my-2 ml-4'>
          {props.dato}
        </p>
      </>)
      }
    </div>
    :
    <div className='flex flex-row items-start justify-start border-primary border-2 mt-2 pl-4 rounded  w-full cursor-pointer'
      onClick={()=>{props.onPress()}}
    >
      <RiSendPlane2Fill size={32} className='mr-2 mt-2 text-primary' style={{maxWidth:20,minWidth:19}}/>
      {correcta? (<>
        {
          correcta===props.dato?
          <>
          <p className='text-left text-lg sm:text-base md:text-lg text-green-200 my-2 ml-4'>
            {props.dato}
          </p>
          </>:
          <>
          <p className='text-left text-lg sm:text-base md:text-lg text-red-200 my-2 ml-4'>
            {props.dato}
          </p>
          </>
        }
      </>):
      (<>
        <p className='text-left text-lg sm:text-base md:text-lg text-primary my-2 ml-4'>
          {props.dato}
        </p>
      </>)
      }
    </div>
  );
}

export default function Pregunta  (props) {
  const dispatch = useDispatch();
  const {revision, correct} = props;
  const examen = useSelector(state=>state.exam.exam);
  const test = useSelector(state => state.test.test);
  const [estados,setEstados] = useState([false,false,false,false]);
  const [revision_,setRevision_] = useState(revision);
  const [currentState,setCurrentState] = useState();
  const [dataProps,setDataProps] = useState(props);

  const changeState = (index,respuesta,idP,idM,estado,revision) => {
    if(revision === 'rev'){
      console.log("esta en revision");
      return
    }
    let newEstados = [false,false,false,false];
    newEstados[index] = !newEstados[index];
    setEstados(newEstados);
    console.log(respuesta);
    handleAddTest(idP+idM,respuesta);
  }

  
  const handleAddTest = (id, opcionMarcada) => {
    console.log(opcionMarcada);
    test.map((item) => {  
      if(item.id === id && item.opcionCorrecta === opcionMarcada){
          item.correcto = true;
          item.opcionSeleccionada = opcionMarcada;
        }
    });
    dispatch(remplaceTest(test));
  }

  useEffect(()=>{
    setCurrentState(examen);
  },[examen]);

  useEffect(()=>{
    setRevision_(revision);
  },[revision]);

  useEffect(()=>{
    setDataProps(props);
  },[props]);

  return(
  <>
    <div className="w-full bg-white flex flex-col justify-start p-8 sm:p-2 md:p-8 rounded mt-4">
      <div className='flex flex-row justify-start items-end border-primary border-b-2'>
        <p className='text-left text-4xl sm:text-xl md:text-4xl text-fondo bg-primary px-2 text-white py-1'>{dataProps.index}.</p> 
        <span className='text-left text-2xl sm:text-base text-gray-800 md:text-2xl  font-bold ml-3'>{dataProps.pregunta}</span>
        
      </div>
      {
        revision_?
        <div className='flex flex-col justify-start items-start mt-4'>
        {
          dataProps.marcado?<>
            {
              dataProps.respuestas[0]=== "" || dataProps.respuestas[0]=== undefined? null :
              <Opcion
              correcta={correct}
              dato={dataProps.respuestas[0]}
              active={dataProps.respuestas[0]===dataProps.marcado?true:false}
              onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState,'rev')}}
            />}
            {dataProps.respuestas[1]=== "" || dataProps.respuestas[1]=== undefined? null :
              <Opcion
              correcta={correct}
              dato={dataProps.respuestas[1]}
              active={dataProps.respuestas[1]===dataProps.marcado?true:false}
              onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState,'rev')}}
            />}
            {dataProps.respuestas[2]=== "" || dataProps.respuestas[2]=== undefined? null :
              <Opcion
              correcta={correct}
              dato={dataProps.respuestas[2]} 
              active={dataProps.respuestas[2]===dataProps.marcado?true:false}
              onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState,'rev')}}
            />}
            {dataProps.respuestas[3]=== "" || dataProps.respuestas[3]=== undefined? null :
              <Opcion
              correcta={correct}
              dato={dataProps.respuestas[3]}
              active={dataProps.respuestas[3]===dataProps.marcado?true:false}
              onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState,'rev')}}
            />}
          </>:
          <>
            {dataProps.respuestas[0]=== "" || dataProps.respuestas[0]=== undefined? null :
              <Opcion 
              correcta={correct}
              dato={dataProps.respuestas[0]}
              active={estados[0]} 
              onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState,'rev')}}
            />}
            {dataProps.respuestas[1]=== "" || dataProps.respuestas[1]=== undefined? null :
              <Opcion
              correcta={correct}
              dato={dataProps.respuestas[1]}
              active={estados[1]}
              onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState,'rev')}}
            />}
            {dataProps.respuestas[2]=== "" || dataProps.respuestas[2]=== undefined? null :
              <Opcion
              correcta={correct}
              dato={dataProps.respuestas[2]} 
              active={estados[2]}
              onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState,'rev')}}
            />}
            {dataProps.respuestas[3]=== "" || dataProps.respuestas[3]=== undefined? null :
              <Opcion
              correcta={correct}
              dato={dataProps.respuestas[3]}
              active={estados[3]}
              onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState,'rev')}}
            />}
          </>
        }

        </div>:
        <div className='flex flex-col justify-start items-start mt-4'>
              <>
                  {dataProps.respuestas[0]=== "" || dataProps.respuestas[0]=== undefined? null :
                    <Opcion 
                   
                    dato={dataProps.respuestas[0]}
                    active={estados[0]} 
                    onPress={()=>{changeState(0,dataProps.respuestas[0],dataProps.idPregunta,dataProps.materia,currentState)}}
                  />}
                  {dataProps.respuestas[1]=== "" || dataProps.respuestas[1]=== undefined? null :
                    <Opcion
                    
                    dato={dataProps.respuestas[1]}
                    active={estados[1]}
                    onPress={()=>{changeState(1,dataProps.respuestas[1],dataProps.idPregunta,dataProps.materia,currentState)}}
                  />}
                  {dataProps.respuestas[2]=== "" || dataProps.respuestas[2]=== undefined? null :
                    <Opcion
                    
                    dato={dataProps.respuestas[2]} 
                    active={estados[2]} 
                    onPress={()=>{changeState(2,dataProps.respuestas[2],dataProps.idPregunta,dataProps.materia,currentState)}}
                  />}
                  {dataProps.respuestas[3]=== "" || dataProps.respuestas[3]=== undefined? null :
                    <Opcion
                    dato={dataProps.respuestas[3]}
                    active={estados[3]}
                    onPress={()=>{changeState(3,dataProps.respuestas[3],dataProps.idPregunta,dataProps.materia,currentState)}}
                  />}
              </>
      
        </div>
      }
      {
        revision_ ?
          dataProps.explicacionCorrecta?
            <p className='w-full p-2 border-primary border-t-2 mt-3'><span className='text-primary'>Fundamento : </span>{dataProps.explicacionCorrecta}</p>
            :
            null
          :
          null
      }
     
    </div>
  </>
  );
};