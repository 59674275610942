import {db, auth} from '../../config/firebase';
import axios from 'axios';
import types from '../types/exam';
import dbRef from '../../config/dbRef';
//import { useDispatch, useSelector } from 'react-redux';
import { doc, updateDoc} from "firebase/firestore";
//import {shuffleArray} from './../../utils/arrays';
//import {getExamId, getMateriaId} from '../../utils/exam';

const getQuestionRequest = payload => ({
    type: types.GET_QUESTION_REQUEST,
    payload: payload,
});

const getQuestionSuccess = payload => ({
    type: types.GET_QUESTION_SUCCESS,
    payload: payload,
});

// const getQuestionFailure = payload => ({
//     type: types.GET_QUESTION_FAILURE,
//     payload: payload,
// });

const loadExamRequest = payload => ({
    type: types.LOAD_EXAM_REQUEST,
    payload: payload,
});

const loadExamSuccess = payload => ({
    type: types.LOAD_EXAM_SUCCESS,
    payload: payload,
});

const loadExamFailure = payload => ({
    type: types.LOAD_EXAM_FAILURE,
    payload: payload,
});

const marcarPreguntaRequest = payload => ({
    type: types.MARCAR_PREGUNTA_REQUEST,
    payload: payload,
});

const marcarPreguntaTestRequest = payload => ({
    type: types.MARCAR_PREGUNTA_TEST_REQUEST,
    payload: payload,
});


const marcarPreguntaSuccess = payload => ({
    type: types.MARCAR_PREGUNTA_SUCCESS,
    payload: payload,
});

const marcarPreguntaTestSuccess = payload => ({
    type: types.MARCAR_PREGUNTA_TEST_SUCCESS,
    payload: payload,
});

const marcarPreguntaFailure = payload => ({
    type: types.MARCAR_PREGUNTA_TEST_FAILURE,
    payload: payload,
});
const marcarPreguntaTestFailure = payload => ({
    type: types.MARCAR_PREGUNTA_FAILURE,
    payload: payload,
});

const saveNotasRequest= payload => ({
    type: types.SAVE_NOTAS_REQUEST,
    payload: payload,
});

const saveNotasSuccess = payload => ({
    type: types.SAVE_NOTAS_SUCCESS, 
    payload: payload,
});

const saveNotasFailure = payload => ({
    type: types.SAVE_NOTAS_FAILURE,
    payload: payload,
});

export const clearExam = () => ({
    type: types.CLEAR_EXAM,
})

export const loarExamTest = (data) => ({
    type: types.LOAD_EXAM_TEST,
})

export const getQuestion = (idUsuario) => {
    return async (dispatch) => {
        try{
            //YLpDjjCWcvMkJPmVUvEn4EyaALf2
            const baseUrl= `https://apri-back.herokuapp.com/exams/${idUsuario}`;
            dispatch(getQuestionRequest());
            const raw = JSON.stringify({
                typeReq: 'get',
              });
            
              const config = {
                method: 'get',
                url: baseUrl,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "access-control-allow-methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "access-control-allow-headers": "Origin, Content-Type, X-Auth-Token, Authorization",
                    'Content-Type': 'application/json',
                },
                data: raw,
              };
            
            const data = await axios(config);
            dispatch(getQuestionSuccess(data.data));
        }
        catch(error){
            console.log(error);
        }
    }
}

export const loadExam = (data) => {
    return async (dispatch) => {
        try{
            dispatch(loadExamRequest());
            //dispatch(loadExam_({examId:examId,estado:estado}));
            dispatch(loadExamSuccess(data));
        }
        catch(error){
            dispatch(loadExamFailure(error));
        }
    }
}

export const loadToExams = (data) => {
    return async (dispatch) => {
        try{
            dispatch(getQuestionSuccess(data));
        }
        catch(error){
            dispatch(loadExamFailure(error));
        }
    }
}

export const marcarRespuesta = (idMateria,idPregunta,marcado,estado) => {
    return async (dispatch) => {
        try{
            let estadoCopy=estado;
            dispatch(marcarPreguntaRequest());
            let indexMateria 
            const copia = estado.find((e,i)=>{
              indexMateria=i
              return e.idMateria === idMateria;
            })
            
            console.log("this:",indexMateria)
            //console.log(copia)
            let indexPregunta;
            
            const copiaOpciones = copia.preguntas.find((e,i)=>{
              indexPregunta=i
              return e.id === idPregunta;
            })
            
            console.log("estado copy:",estadoCopy[indexMateria].preguntas)
            console.log("index pregunta:",indexPregunta)
            
            estadoCopy[indexMateria].preguntas[indexPregunta].marcado=marcado;
            dispatch(marcarPreguntaSuccess(estadoCopy));
        }
        catch(error){
            dispatch(marcarPreguntaFailure(error));
        }
    }
}
export const marcarRespuestaTest = (idMateria,idPregunta,marcado,estado) => {
    return async (dispatch) => {
        try{
            let estadoCopy=estado;
            dispatch(marcarPreguntaTestRequest());
            let indexMateria 
            const copia = estado.find((e,i)=>{
              indexMateria=i
              return e.idMateria === idMateria;
            })
            
            console.log("this:",indexMateria)
            //console.log(copia)
            let indexPregunta;
            
            // const copiaOpciones = copia.preguntas.find((e,i)=>{
            //   indexPregunta=i
            //   return e.id === idPregunta;
            // })
            estadoCopy[indexMateria].preguntas[indexPregunta].marcado=marcado;
            dispatch(marcarPreguntaTestSuccess(estadoCopy));
        }
        catch(error){
            dispatch(marcarPreguntaTestFailure(error));
        }
    }
}

export const saveNotas = (notas,notasA)=>{
    return async (dispatch) =>{
        try{
            dispatch(saveNotasRequest());
            const ex = notasA.filter((e)=>e.id === notas.idCuenta)
            const notas_ = ex[0].notas?ex[0].notas:[];

            const userId = auth.currentUser.uid
            const notas__=[...notas_,notas];

            const cuenta = doc(db, dbRef.user, userId,'cuenta',notas.idCuenta);

            await updateDoc(cuenta, {notas:notas__});
            dispatch(saveNotasSuccess(notas));
        } catch(error){
            dispatch(saveNotasFailure(error));
        }
    }
}