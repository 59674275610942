/* eslint-disable no-unused-vars */
import React,{useState, useEffect} from "react";
import jwt from 'jsonwebtoken';
import {privateToken} from '../ExamTest/getExam';
import Footer from "../components/footer";
import {addCart} from '../../redux/actions/cart';
import {useDispatch, useSelector} from 'react-redux';
import dbRef from "../../config/dbRef";
import { getAuth } from "firebase/auth";
import {db} from '../../config/firebase'
import { collection} from "firebase/firestore";
import { doc, addDoc } from "firebase/firestore";
import { Bar } from "../components/onload";
import { getUser } from '../../redux/actions/user';
import {auth} from '../../config/firebase';
import { Contactanos } from "../components/uiComponents";
import { getExamTest } from "../ExamTest/getExam";
import {Link} from 'react-router-dom';
import { map } from "@firebase/util";

const Live = (props) => {
  //const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [id,setId]= useState('');
  const [data ,setData]= useState();
  const [textBtn,setTextBtn]= useState("Adquirir Plan");
  const [allOk ,setAllOk]= useState(false);
  console.log(props.location.data);

  useEffect(() => {
    try {
    if(props.location.data){
     
        console.log(props.location.data.data)
        setData(props.location.data.data);
        setAllOk(true);
    }else{
      setAllOk(false);
    }
    } catch (error) {
      setAllOk(false);
    }
    
  } ,[props.location.data])

    return (
        <>
        {allOk ? 
          <>
            <div className="">
              <h1 className="text-center text-2xl font-bold text-white mt-20 bg-contexto py-6 px-10">Apriori - Eventos, una diferente manera de aprender{/*data.nombre&&data.nombre*/}</h1>
            </div>
            <div className="px-32 py-16 flex flex-row sm:flex-col-reverse md:flex-row sm:px-4 md:px-32 sm:py-8 md:py-16">
              <div className="w-3/4 sm:w-full dm:w-3/4">
                <div className="px-4 sm:px-2 md:px-4">
                  <h2 className="text-4xl text-primary sm:text-2xl md:text-4xl">{data.nombre}</h2>
                  <h3 className="text-2xl text-primary sm:text-xl md:text-2xl">Expositor:</h3>
                  {data.expositor.map(expositor => {
                    return(
                      <p className="text-xl text-black">{expositor}</p>
                    )
                  })
                }
                  <p>{/*data.expositor&&data.expositor*/}</p>
                  <h3 className="text-2xl text-primary sm:text-xl md:text-2xl">Contenido:</h3>
                  <ul className="ml-8">
                  {
                    data.contenido.map(expositor => {
                      return(
                        <li type='disc' className="text-xl text-black">{expositor}</li>
                      )
                    })
                  }
                  </ul>
                  <h3 className="text-2xl text-primary sm:text-xl md:text-2xl">Recuros:</h3>
                  <ul className="ml-8">
                  {
                    data.recursos.map(expositor => {
                      return(
                        <li type='disc' className="text-xl text-black">
                          <a href={expositor.url} target="_blank" rel="noopener noreferrer">{expositor.nombre}</a>
                        </li>
                      )
                    })
                  }
                  </ul>
                  

                </div>
              </div>
              <div className="bg-green-100 w-full">
              <iframe 
                width="100%" 
                height="458" 
                src={data.urlVideo}
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                allowfullscreen
              ></iframe>
              </div>
            </div>
          </> 
          : 
          <>
            <div className="px-32 py-16 flex flex-col w-full h-80 justify-center items-center text-center mt-8">
              <h1 className="text-5xl">Algo salio mal</h1>
              <h1 className="text-5xl my-4">:-(</h1>
              <Link to="/profile" className="bg-primary hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full  my-4">
                Volver a perfil
              </Link>
            </div>
          </>
          }
        <Footer/>
        
        </>
    );
}

export default Live;