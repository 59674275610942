import types from '../types/dataPage';

const initialState = {
    dataPage: [],
    loading: false,
    error: null,
};

export const dataPageReducer =  (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case types.GET_DATA_SUCCESS:
            return {
                ...state,
                dataPage: [action.payload],
                loading: false,
                error: null,
            };
        case types.GET_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default: 
            return state;
    }
}