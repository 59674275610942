import React ,{useState, useEffect} from 'react';
import Banner from '../components/banner';
import Simulacrum from '../components/simulacrum';
import ContecParallax from '../components/parallax';
import Comments from '../components/comments';
import NextQuestion from '../components/nextQuestion';
import Footer from '../components/footer';
import Company from '../components/company';
import {useDispatch, useSelector} from 'react-redux';
import {getDataPage} from '../../redux/actions/dataPage';


export default function Home(){

    const data = useSelector(state => state.dataPage);

    const [examen , setExamen] = useState('Examen Proximo...');
    const [titulo , setTitulo] = useState('Escuela de Jueces');
    const [idEvento, setIdEvento] = useState('');
    const [tipo, setTipo] = useState();
    const [hora , setHora] = useState('3/30/2022 9:30 AM')
    const [datos , setDatos] = useState([]);


    const dispatch = useDispatch();
    
    const bar = document.getElementById('bar');
    if (bar) {
      bar.style.display = 'block';
    }
  

    useEffect(() => {
      dispatch(getDataPage());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (data.dataPage.length > 0) {
        setExamen("Próximo Examen:");
        setDatos(data.dataPage[0]);
        setTitulo(data.dataPage[0].tituloBanner);
        setIdEvento(data.dataPage[0].idEvento);
        setTipo(data.dataPage[0].tipo);
        setHora(data.dataPage[0].fechaExamen);
        
      }
    },[data]);
    

    return(
    <>
        <Banner examen={examen} titulo={titulo} hora={hora} data = {datos.examenCpmpleto} idEvento={idEvento} tipo={tipo}/>
        <Simulacrum datos = {datos} />
        <ContecParallax/>
        <Comments tema={'claro'}/>
        <NextQuestion/>
        <Company/>
        <Footer mensaje={true}/>
    </>
    );
};