import jwt from 'jsonwebtoken';
import {db} from '../../config/firebase'
import { collection, getDocs } from "firebase/firestore";
import { query, orderBy, limit } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";

const shffleArray = (inputArray) => inputArray.sort(() => Math.random() - 0.5);
export const privateToken = "apriori123-.";

export const getExamTest = async (data) =>
new Promise(async (resolve, reject) => {
    try{
        console.log(data,"aaaaaaaaaaaaaaaaaqqqqqqqqqqqqqquiieee")
        const privateAccesToken= privateToken;

    //    const materias = data
        //convertir data en json string 
  //      const materiasJson = JSON.stringify(materias);

//        const token = jwt.sign(materiasJson, privateAccesToken);

        //console.log(token, "tokeeennnss")
        // desencryptar token 
        const decoded = jwt.verify(data, privateAccesToken);
        //console.log(decoded, "tokeeennnss desencryptado")
        // const data = await
        const idMaterias=[];
        // if(decoded.length>3){
        //     for(let i= 0; i<3; i++){
        //         idMaterias.push({id:decoded[i].Id,name:decoded[i].nombre});
        //     }
        // }else{
        decoded.map((item)=>{
            idMaterias.push({id:item.Id,name:item.nombre});
        });
        // }
        const maxQuestions = 5;
        const materias_final = [];

        await Promise.all(
            idMaterias.map(async (idM)=>{
                const matRef = collection(db, idM.id);
                const q = query(matRef, orderBy("id"), limit(maxQuestions));
                const quest = await getDocs(q);
                const preguntas = [];
                quest.forEach((doc) => {
                preguntas.push(doc.data());
                });
                preguntas.map((pregunta) => shffleArray(pregunta.opciones));
                const f = {
                    idExamen: "test",
                    idCuenta: "_test_acc",
                    plan: "test_plan",
                    idMateria: idM.id,
                    materia: idM.name,
                    preguntas: preguntas,
                }
                materias_final.push(f);
            })
        )
        // console.log(materias_final, "materias_final")
        resolve(materias_final);
    }catch(error){
        reject(error);
    }
});