import types from '../types/cart';

const addCartSuccess = payload => ({
    type: types.ADD_CART_SUCCESS,
    payload:payload,
});

export const addCart = (payload) => {
    return async (dispatch) => {
        try{
          dispatch(addCartSuccess(payload))
          console.log(payload);
        } catch(error){
            alert("Error Cart:", error);
        }
    };
}