import React,{useEffect, useState, useRef} from "react";

import { db } from "../../config/firebase";
import dbRef from "../../config/dbRef";
import { collection, getDocs } from "firebase/firestore";
import { doc, addDoc, updateDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

const Management = (props) => {
  const [users, setUsers] = useState([]);
  const [param , setParam] = useState('xxxxx');

  const [codigoAcceso, setCodigoAcceso] = useState('');

  useEffect(() => {
    console.log(users);
  }, [users]);

  useEffect(()=>{
    getDocs(collection(db,dbRef.user))
    .then((response)=>{
        let user =[]
        response.forEach((doc)=>{
          user.push(
            {
              id:doc.id,
              data:doc.data(),
            }
          );
          // console.log(user);
        });
        return user;
    }).then( doc =>{setUsers(doc);console.log(users)})
},[])
    return (
      <>
      {
        codigoAcceso === '123321'?
        <div className="flex flex-row justify-center items-center mt-16 flex-wrap">
          <div className="w-full flex flex-row m-16">
            <input type="text" className="w-full border-2 border-gray-400 rounded-lg p-2" placeholder="Buscar telefono"
              onChange={(e) => setParam(e.target.value)}
            />
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mx-2"
              onClick={() => {
                setParam(param);
              }}

            >Buscar</button>
          </div>
          <div className="w-full flex flex-row m-16">
            <div className="w-3/4 min-w-3/4 bg-textBajo flex flex-wrap m-1 items-stretch justify-items-stretch">
              {
                users?
                // <h1 className="mt-8 pt-8" >Carga.</h1>
                users.map((user,index)=>{
                  if(user.data.name.includes(param)|| user.data.phone.includes(param)){

                    return <div key={index} className="rounded w-1/3 min-w-1/3 p-1">
                  <div className="card text-gray-800 flex flex-col justify-around bg-gray-200 p-2 rounded-sm">
                    <div className="">
                      <div className ="text-gray-800 font-bold text-base uppercase text-center -mx-3">{user.data.name}</div>
                      <hr className="color-gray-800"/>
                      <p className="font-normal text-14 text-center -mx-3 my-1">{user.data.lastName}</p>
                      
                    </div>
                    <div className="text-sm">
                      <div>
                        <span className="font-regular text-primary">Telefono:</span> 
                        <span>  {user.data.phone}</span>
                      </div>
                      <div>
                        <span className="font-regular text-primary">correo:</span> 
                        <span>  {user.data.email}</span>
                      </div>
                      <div>
                        <span className="font-regular text-primary">contrasenia:</span>
                        <span>  {user.data.password}</span>
                      </div>
                      {
                        user.data.codeAfiliate&&
                        <div className="bg-gray-400 rounded-sm">
                          <span className="font-regular text-primary">Cod. Afiliado:</span>
                          <span>  {user.data.codeAfiliate}</span>
                        </div>
                      }
                      
                      <div className="font-normal text-xs"> 
                      {/* <span className="font-bold text-primary"> Colaboradores :</span>
                        <ul>
                          {user.data.colaboradores.map((colaborador,index)=>{
                            return <li key={index}>{colaborador}</li>
                          })}
                        </ul> */}
                        <span className="text-xs mx-0"> {user.id}</span>
                        <div className="mt-4 items-self-center">
                          <Link to={{
                            pathname:`/counts`,
                            id : user.id,
                            codeAfiliate : user.data.codeAfiliate,
                          }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mx-2">Editar</Link>
                          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mx-2"
                            onClick={() => {
                              setParam(param);
                            }
                            }
                          >Ver Examenes</button>
                          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg mx-2"
                            onClick={() => {
                              setParam(param);
                            }
                            }
                          >Eliminar</button> */}
                        </div>
                      </div>
                      
                    </div>
                    
                  </div>
                </div>
                  }
                })
                :
                <h1 className="mt-8 pt-8" >Cargando...</h1>
              }
            </div>
            <div className="w-1/4 min-w-1/4 bg-textBajo">
              <p>Hola comoestas </p>
            </div>
        </div>
        </div>
        :
        <div className="flex flex-row justify-center items-center mt-16 flex-wrap">
          <div className="w-full flex flex-row m-16">
            <input type="text" className="w-full border-2 border-gray-400 rounded-lg p-2" placeholder="Codigo de acceso"
              onChange={(e) => setCodigoAcceso(e.target.value)}
            />
          </div>
        </div>
      }
      </>
    );
}

export default Management;