import { auth, db } from "../../config/firebase";
import dbRef from "../../config/dbRef";
import { collection, getDocs } from "firebase/firestore";
import { doc, addDoc, updateDoc , query,where, getDoc } from "firebase/firestore";

export const getMaterias = (id) => 
    new Promise(async(resolve, reject) => {
        try {
            const docRef = doc(db, dbRef.exam, id);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            } 
            resolve(docSnap.data().materias);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });

export const getQuestion = (id) => 
    new Promise(async(resolve, reject) => {
        try {
            const q = query(collection(db, id));
            const arrayPreguntas = await getDocs(q);
            const preguntas = [];
            arrayPreguntas.forEach((doc) => {
            preguntas.push(doc.data());
            });
            shffleArray(preguntas);
            preguntas.map((pregunta) => shffleArray(pregunta.opciones));
            console.log(preguntas);
            resolve(preguntas);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });

export const createExam = async(idExamen, cantidadExamenes, cantidadPreguntas,iduser,idCuentaExamn,idExamenGeneral, plan) =>{
    try {
        let examenPreguntas = [];
        let cantidadMaterias = 0;

        const materias = await getMaterias(idExamen);
        cantidadMaterias = materias.length;
        //cantidad de preguntas / cantidad de materias * cantidad de examenes
        await Promise.all(materias.map(async(materia) => {
            let materiaPreguntas = {};
            const preguntas = await getQuestion(materia.Id);
            materiaPreguntas = {
                idMateria: materia.Id,
                nombre: materia.nombre,
                preguntas: preguntas
            };
            examenPreguntas.push(materiaPreguntas);
        }));


        let arrayPreguntaPorMateria = calculatePreguntaPorMateria(cantidadPreguntas, cantidadMaterias);


        let examenFinal=[]
    for(let i=1;i<=cantidadExamenes;i++){
        let examenTemp=[];

        arrayPreguntaPorMateria.forEach((element,index)=>{
            const inicio = (i-1)*element;
            const final = i*element-1;
            const materia = index;
            /**
             * 
             * idExamen(pin):"4s4Fm0qx1qz0D5tZiJkj"
                idCuenta(pin):"o8OJXLCsGYrH6NfzLX1j"
                plan(pin):"Premium"
                idMateria(pin):"DerechoConstitucional"
                materia(pin):"Derecho Constitucional"
             */
            
            let newmateria = {
                idExamen: idExamenGeneral,
                idCuenta: idCuentaExamn,
                plan: plan,
                idMateria:examenPreguntas[materia].idMateria,
                materia:examenPreguntas[materia].nombre,
                preguntas:examenPreguntas[materia].preguntas.slice(inicio, final+1)
            };

            examenTemp.push(newmateria);//psuch
            
        })
        examenFinal.push(examenTemp);

    }

    const examenes = doc(db, dbRef.user + '/' + iduser);
    console.log(examenFinal);
    let arrayIDs = [];

    await Promise.all(examenFinal.map(async(examn,index) => {
        let examennuevo = await addDoc(collection(examenes, 'examenes'), {
            id: index,
            examen: examn,
            idCuentaExamn: idCuentaExamn
        });
        
        arrayIDs.push({id:examennuevo.id,isResolve:false});
    }));
    //(db, dbRef.user, iduser, 'cuenta', idCuentaExamn)
    //const messageRef = doc(db, "rooms", "roomA", "messages", "message1");
    const refCuenta =doc(db, dbRef.user,iduser,'cuenta',idCuentaExamn); //doc(db, dbRef.user + '/' + iduser+'/cuenta/',idCuentaExamn);
    await updateDoc(refCuenta, { examenes: arrayIDs });

    alert("Examen creado correctamente");
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

const calculatePreguntaPorMateria = (cantidaPreguntas, cantMaterias) =>  {
    const cantPorMateria = Math.floor(cantidaPreguntas / cantMaterias);
    let resto = Math.abs(cantPorMateria * cantMaterias - cantidaPreguntas);
    console.log(cantPorMateria,resto)
    const nPPorMateria = [];
    for(let i =0; i< cantMaterias;i++) {
        
    if (resto > 0) {
        nPPorMateria.push(cantPorMateria+1);
        resto = resto - 1;
    } else {
        nPPorMateria.push(cantPorMateria);
    }
    }
    return nPPorMateria;
}

const shffleArray = (inputArray) => inputArray.sort(() => Math.random() - 0.5);



// let cantidaPreguntas = 7;
// let cantMaterias =3 ;
// let cantidadExamenes = 2;

// let arrayMaterias = [0,0,0];


//console.log(examenFinal);

