export default function Panel({panelId, panelSelect, btnNext, btnPrev, btnSubmit, error, errorMessage , children}) {
    return (
    <> 
        <div className=' flex flex-col justify-center p-5 w-full' style={{display:`${panelId===panelSelect?'flex':'none'}`}} >
        {children}
        {error?
            <div role="alert">
                <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                    
                </div>
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                    {errorMessage?
                        <p>{errorMessage}</p>:
                        <p>complete el formulario antes de continuar</p>
                    }

                </div>
            </div>:null
        }
        <div className='flex flex-row justify-center '>
            
            {btnPrev?
                <div 
                  className="w-1/3 py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-transparent hover:text-primary mt-3"
                  onClick={btnPrev}>
                    Atras
                </div>:null
            }
            {btnNext?
                <div
                  className="min-w-1/2 ml-3 w-1/3 py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-transparent hover:text-primary mt-3"
                  onClick={btnNext}
                >
                    Siguiente
                </div>:null
            }
            {btnSubmit?
                <button
                 className="min-w-1/2 ml-3 py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-transparent hover:text-primary mt-3"
                 onClick={btnSubmit}
                >
                 Registrar
                </button>:null
            }

         </div>
         <div className="flex flex-col text-center justify-center mt-3">
             <p className="text-sm text-gray-1000 w-full°">Tus datos estan protegidos por nuestras</p><a className="text-sm text-blue-500" href="https://www.google.com/">Politicas de privacidad</a>
         </div>
        </div>

    </>
    )
}