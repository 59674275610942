import React from 'react';

export default function Privada(){
    return(
    <>
        <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
                <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
        <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
        <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
        <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
                <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
        <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
        <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
        <div className="bg-green-50">
            <p>estas en una pantalla privada</p>
        </div>
    </>
    );
};