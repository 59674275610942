import React, {useEffect} from 'react';

import {BrowserRouter as Router,Switch} from 'react-router-dom';
import Navbar from './componets/Navbar'
import routes from './routes/routes';
import AppRoute from './routes/AppRoute';
import ReactGA from 'react-ga';
import './App.css';

function App(props) {
  useEffect(() => {
    console.log('alalitycs1');
    ReactGA.initialize('UA-207003826-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log('alalitycs2');
  } , []);


  return (
    <>
    <Router>
     <Navbar />
      <Switch>
        {
          routes.map(route =>(
            <AppRoute
              key={route.path}
              path={route.path}
              component={route.component}
              isPrivate={route.isPrivate}
              exact
            />
          ))
        }
      </Switch>
    </Router>
    </>
    
  );
}

export default App;
