import types from '../types/exam';

const initialState = {
    exams: [],
    exam: null,
    examTest: null,
    loading: false,
    error: null,
    notas:null
};

export const examReducer =  (state = initialState, action) => {
    switch (action.type) {
        case types.GET_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case types.GET_QUESTION_SUCCESS:
            return {
                ...state,
                exams: action.payload,
                loading: false,
                error: null,
            };
        case types.GET_QUESTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.LOAD_EXAM_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.LOAD_EXAM_SUCCESS:
            return {
                ...state,
                exam: action.payload,
                loading: false,
                error: null,
            };
        case types.LOAD_EXAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.CLEAR_EXAM:
            return {
                ...state,
                exam: null,
                loading: false,
                error: null,
            };
        
        case types.MARCAR_PREGUNTA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.MARCAR_PREGUNTA_SUCCESS:
            return {
                ...state,
                exam: action.payload,
                loading: false,
                error: null,
            };
        case types.MARCAR_PREGUNTA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.MARCAR_PREGUNTA_TEST_REQUEST:
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
        case types.MARCAR_PREGUNTA_TEST_SUCCESS:
                return {
                    ...state,
                    examTest: action.payload,
                    loading: false,
                    error: null,
                };
        case types.MARCAR_PREGUNTA_TEST_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
        case types.SAVE_NOTAS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.SAVE_NOTAS_SUCCESS:
            return {
                ...state,
                notas: action.payload,
                loading: false,
                error: null,
            };
        case types.SAVE_NOTAS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.LOAD_EXAM_TEST:
            return {
                ...state,
                examTest: action.payload,
                loading: false,
            };
        default : 
            return state;
    }
}