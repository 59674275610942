export const HOME = '/';
export const LOGIN = '/login';
export const PRIVADA = '/privada';
export const REGISTER = '/register';
export const SIGNUP = '/signup';
export const SERVICE = '/service';
export const TEAM = '/team';
export const CONTACT = '/contact';
export const PRICING = '/pricing';
export const PROFILE = '/profile';
export const EXAMEN = '/examen/:idExamen';
export const DETAILS = '/details';
export const CONVOCATORIA = '/convocatoria';
export const PLANS = '/plans';
export const SUGGESTIONS = '/suggestions';
export const EXAMTEST = '/examtest/:idMaterias';
export const MANAGEMENT = '/management';
export const EVENTS = '/events/:idEvent';
export const REGISTER_TO_EVENT = '/register-to-event/:idEvent';
export const LIVE = '/live';
export const COUNTS = '/counts';
export const PAYMENT = '/payment';
export const DRIVER = '/driver';
export const USERPROFILE = '/user-profile';
export const LICENCIAS = '/licencias';
export const PRINT = '/print';
export const ADMINAFILIATE = '/adminAfiliate';
export const COPIA = '/copia/:idCuenta';