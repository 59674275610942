import React from 'react';
import Navbar from '../../componets/navBarMenu';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Print = () => {

    const user = useSelector((state) => state.user.user);
    const examn = useSelector((state) => state.user.cuenta);
    const Data = useSelector((state) => state.exam.exams);
    const bar = document.getElementById('bar');
    if (bar) {
      bar.style.display = 'none';
    }

    return (
      <>
        <Navbar />

        <div
            className="flex w-full px-40 sm:px-5 lg:px-40 py-4 justify-center flex-col text-center"
        >
        
            <h1
              className="text-3xl font-bold text-primary uppercase"
            >Prueba { examn[0].nombre}</h1>
            <hr className="w-full mx-auto my-1" />
            <h3
              className="text-xl text-gray-400"
            >
              {user.name + ' ' + user.lastName}
            </h3>
            <p
             className="text-sm text-gray-400"
            >
              {
                new Date().toLocaleDateString('es-ES', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              }
            </p>

            <div className="flex flex-col w-full justify-start justify-self-start text-left">
              {Data.map((item, index) => (<>
                  <h2
                    key={index}
                    className="text-2xl font-bold bg-primary text-white p-3 rounded-xl mt-4 my-4"
                  >{item.materia}</h2>
                  <div
                    className="px-4"
                  >
                    {item.preguntas.map((pregunta, index) => (
                      <div key={index} className="flex flex-col w-full justify-start justify-self-start text-left mb-4">
                        <h3
                          className="text-xl text-black border-b-2 border-black"
                        >
                          <span className="text-base font-bold text-primary"> {index+1}: </span>
                          {pregunta.pregunta}</h3>
                        <div className="flex flex-col w-full justify-start justify-self-start text-left">
                          {pregunta.opciones.map((respuesta, index) => (
                            <p
                              key={index}
                              className={
                                `${
                                  respuesta === pregunta.opcionCorrecta?
                                  respuesta === pregunta.marcado?
                                    'text-green-500' : 'text-red-500'
                                    : 'text-gray-500'
                                } 
                                ${
                                  respuesta === pregunta.opcionCorrecta&& 'text-green-500'
                                } 
                                ${
                                  respuesta === pregunta.marcado&& respuesta !== pregunta.opcionCorrecta && 'text-red-500'
                                }


                                `
                              }
                            >
                              {
                                respuesta && <>
                                
                                <span className="text-base font-bold text-primary">■ </span>
                                <span>{respuesta}</span>
                                </>
                              }
                              
                            </p>
                          ))}
                        </div>
                        <p className="text-sm text-gray-400">
                          <span className="text-base font-bold text-primary">Justificacion: </span>
                          {pregunta.explicacionCorrecta}
                        </p>
                      </div>
                      ))}
                  </div>
                </>
              ))}
            </div>

            <p
              className="text-sm text-gray-400"
            >
              Para guardar como pdf despues de presionar el boton, en el apartode impresion, seleccionar la opcion "guardar como pdf" y listo.
            </p>
            <button
              className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-1/2 mx-auto my-4"
              onClick={() => {
                window.print() 
                //redirect to home

              }
              }
            >
              guardar como pdf
            </button>
            <Link 
            className='font-bold text-primary hover:text-blue-700'
            to="/profile">
              Ir a Perfil
            </Link>
        </div>


      </>
    );
};

export default Print;